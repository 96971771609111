import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import { formatDayjsDateWithoutTime } from '../../../../common/utils/dateUtil';
import { hasSubmitted } from '../../utilities';
import { ProcessedRmaDisplay } from './ProcessedRmaDisplay';
import { ResubmitRmaHistoryDisplay } from './ResubmitRmaHistoryDisplay';

export const SubmittedRmaInfoDisplay = () => {
    const rma = useAppSelector(state => state.rma.rma);

    if (!hasSubmitted(rma?.Status)) return <React.Fragment />;

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography fontWeight={'fontWeightMedium'} variant="h5" color="primary.main" gutterBottom>
                    PROCESS DETAILS
                </Typography>
            </Box>
            <Card>
                <CardContent>
                    <Box sx={{ display: 'flex', p: 2, flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <Typography>DATE SUBMITTED: {formatDayjsDateWithoutTime(rma?.DateUpdated)}</Typography>
                            <Typography>BY: {rma?.ProcessedBy}</Typography>
                        </Box>
                        <ProcessedRmaDisplay />
                    </Box>
                    <ResubmitRmaHistoryDisplay />
                </CardContent>
            </Card>
        </React.Fragment>
    );
};
