import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import { RecSNCheckRequest, RecSNCheckResponse, SerialVerifyRegexRequest } from './models';

const apiWithTag = baseApi.enhanceEndpoints({
    addTagTypes: [],
});

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        getIsSerailInRecSN: builder.query<boolean, RecSNCheckRequest>({
            query: request => ({
                url: `${prependDreamUrl(`api/qc/checkSerial/recsn`)}`,
                method: 'GET',
                params: request,
            }),
            transformResponse: (response: RecSNCheckResponse): boolean => {
                return !!response?.InRecSN;
            },
        }),
        getSerialVerifyRegex: builder.query<string, SerialVerifyRegexRequest>({
            query: request => ({
                url: `${prependDreamUrl(`api/qc/serialVerify/regex`)}`,
                method: 'GET',
                params: request,
            }),
        }),
    }),
    overrideExisting: true,
});
