import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { GridRenderEditCellParams, GridTreeNodeWithRender, useGridApiContext } from '@mui/x-data-grid';
import { PrinterRecord } from '../models';

//https://mui.com/x/react-data-grid/custom-columns/

export function EditPrinterName(props: GridRenderEditCellParams<PrinterRecord, any, any, GridTreeNodeWithRender>) {
    const { id, value, field } = props;

    const apiRef = useGridApiContext();

    const handleChange = async (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
        await apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
        apiRef.current.stopCellEditMode({ id, field });
    };

    const options = 'valueOptions' in props.colDef ? props.colDef.valueOptions : ([] as any);

    return (
        <Autocomplete
            value={value}
            onChange={handleChange}
            options={options}
            fullWidth
            freeSolo
            renderInput={params => <TextField {...params} fullWidth />}
        />
    );
}
