import { Box, Button, Dialog, DialogContent, DialogTitle, Paper, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Rma } from '../rmaModels';
import { setRma as setRmaSlice } from '../rmaSlice';
import { hasSubmitted } from '../utilities';
import { formatDayjsDateWithoutTime } from '../../../common/utils/dateUtil';
import { grey } from '@mui/material/colors';
import DialogCloseButton from '../../../common/components/Dialog/DialogCloseButton';

export const ChooseRmaDialog = React.memo(
    ({
        rmas,
        handleOpenDialog,
        setExpectedItemsDialog,
        showDialog = false,
    }: {
        rmas?: Rma[];
        handleOpenDialog: (openState: boolean) => void;
        setExpectedItemsDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const dispatch = useDispatch();
        const selectRma = (rma: Rma) => {
            dispatch(setRmaSlice(rma));
            setExpectedItemsDialog(true);
            handleOpenDialog(false);
        };

        if (!!!rmas) {
            return <React.Fragment />;
        }

        return (
            <Dialog
                open={showDialog}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    sx: { backgroundColor: grey[100] },
                }}
            >
                <DialogTitle color="white" bgcolor="primary.main" align="center">
                    <Typography fontWeight={'fontWeightMedium'} variant="h5">
                        MULTIPLE RMA RECORDS FOUND
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        {rmas.map((rma, index) => (
                            <Paper sx={{ m: 2, pb: 3 }}>
                                <Box bgcolor={'primary.main'} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography color="white" variant="h6">
                                        RMA #{index + 1}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography>RMA Number:</Typography>
                                                <Typography>CLIENT: </Typography>

                                                <Typography>RMA Created On:</Typography>
                                                <Typography>Tracking Number:</Typography>
                                                <Typography>Status:</Typography>
                                                {hasSubmitted(rma.Status) && (
                                                    <React.Fragment>
                                                        <Typography sx={{ ml: 2 }}> - Processed On:</Typography>
                                                        <Typography sx={{ ml: 2 }}> - Processed By:</Typography>
                                                    </React.Fragment>
                                                )}
                                                <Typography>
                                                    Expected Item SKU{rma.ExpectedItems.length > 1 ? 's' : ''}:
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                                                <Typography>{rma.AltRmaNumber}</Typography>
                                                <Typography>{rma.Client}</Typography>
                                                <Typography>{formatDayjsDateWithoutTime(rma.DateAdded)}</Typography>
                                                <Typography>
                                                    {rma.TrackingNumbers.length > 0 ? rma.TrackingNumbers[0] : '-'}
                                                </Typography>
                                                {hasSubmitted(rma.Status) ? (
                                                    <React.Fragment>
                                                        <Typography color={'success.main'}>
                                                            Already Processed
                                                        </Typography>
                                                        <Typography>
                                                            {formatDayjsDateWithoutTime(rma.DateUpdated)}
                                                        </Typography>
                                                        <Typography>{rma.ProcessedBy}</Typography>
                                                    </React.Fragment>
                                                ) : (
                                                    <Typography color={'warning.main'}>Not Processed</Typography>
                                                )}
                                                {rma.ExpectedItems.map(item => (
                                                    <Typography>{item.Sku}</Typography>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', mt: 2 }}>
                                        <Button
                                            onClick={() => {
                                                selectRma(rma);
                                            }}
                                            variant="contained"
                                            size="large"
                                        >
                                            SELECT
                                        </Button>
                                    </Box>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                    <DialogCloseButton
                        handleClose={() => {
                            handleOpenDialog(false);
                        }}
                    />
                </DialogContent>
            </Dialog>
        );
    }
);
