import {
    Card,
    Dialog,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';
import { formatDate } from '../../utils/dateUtil';

interface Log {
    Id: number;
    User: string;
    Changes: string;
    CreateDateTime: string;
}

interface EditHistoryCardProps<T extends Log> {
    logs: T;
}

interface HistoryChangesRow {
    item: string;
    old: string;
    new: string;
}

const EVENT_TITLE = 'Event';

const EditHistoryCard = <T extends Log>({ logs }: EditHistoryCardProps<T>) => {
    const [open, setOpen] = React.useState(false);

    const handleCardClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //"{\"Location\":{\"+\":\"\",\"-\":\"Engle-A\"},\"ScDeliveryApt\":{\"+\":true,\"-\":false},\"ScPlqty\":{\"+\":true,\"-\":false}"
    const castToHistoryChangesRow = (input: string): HistoryChangesRow[] => {
        if (!/(\{|\})/g.test(input))
            return [{ item: EVENT_TITLE, old: `Not ${input}`, new: input } as HistoryChangesRow];

        try {
            let historyChangesRequestObj = JSON.parse(input.replace('\\', ''));
            let result: HistoryChangesRow[] = [];

            for (const [key, value] of Object.entries(historyChangesRequestObj) as [string, any]) {
                const newRow: HistoryChangesRow = {
                    item: key,
                    old: typeof value['-'] === 'boolean' ? (value['-'] ? 'true' : 'false') : value['-'] ?? 'null',
                    new: typeof value['+'] === 'boolean' ? (value['+'] ? 'true' : 'false') : value['+'] ?? 'null',
                };
                result = [...result, newRow];
            }

            return result;
        } catch {
            return [
                {
                    item: 'Error Parsing JSON. Please ask IT to view edit history log.',
                    new: '',
                    old: '',
                },
            ];
        }
    };

    const changesRows = castToHistoryChangesRow(logs.Changes);

    return (
        <>
            {' '}
            <Card variant="outlined" sx={{ p: 1, m: 2, display: 'flex', cursor: 'pointer' }} onClick={handleCardClick}>
                <Typography variant="body1" sx={{ flex: 1 }}>
                    {logs.User}
                </Typography>
                <Typography variant="body1" sx={{ flex: 1 }} align="center">
                    {formatDate(logs.CreateDateTime)}
                </Typography>
                <Typography variant="body1" sx={{ flex: 1, whiteSpace: 'pre-line' }} align="right">
                    {changesRows
                        .map(row => {
                            if (row.item === EVENT_TITLE) return row.new;
                            return row.item;
                        })
                        .join('\n')}
                </Typography>
            </Card>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle textAlign={'center'}>
                    Edited by {logs.User} on {formatDate(logs.CreateDateTime)}
                </DialogTitle>
                <EditHistoryCardDialogBody rows={changesRows} />
            </Dialog>
        </>
    );
};

const EditHistoryCardDialogBody = ({ rows }: { rows: HistoryChangesRow[] }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="right">From</TableCell>
                        <TableCell align="right">To</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.item} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {row.item}
                            </TableCell>

                            <TableCell align="right">
                                {prettifyString(row.old)
                                    .split('\n')
                                    .map((curs, idx) => (
                                        <p key={idx}>{curs}</p>
                                    ))}
                            </TableCell>
                            <TableCell align="right">
                                {prettifyString(row.new)
                                    .split('\n')
                                    .map((curs, idx) => (
                                        <p key={idx}>{curs}</p>
                                    ))}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const prettifyString = (input: string): string => {
    const stripped = JSON.stringify(input)
        .replaceAll(/(\{)/g, '\n')
        .replaceAll(/(\[|\]|\"|\{|\})/g, '')
        .replaceAll(',', '\t');

    const removedNullAndBlanks = stripped
        .split('\t')
        .filter(token => !token.match(/(null|Id:0)/g))
        .join('\t');

    return removedNullAndBlanks || 'none';
};

export default EditHistoryCard;
