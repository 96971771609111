import { skipToken } from '@reduxjs/toolkit/query';
import { useAppDispatch } from '../../app/store';
import authenticationApi from '../../features/authentication/authenticationApi';
import { setDialog } from '../../features/notification/notificationSlice';
import { PrintRequest } from '../../features/printer/models';
import printerApi from '../../features/printer/printerApi';
import useMutationResponse from './useMutationResponse';
import useQueryResponse from './useQueryResponse';

//returns array where
//element 0 is print function based on hook arguments
//element 1 is name of printer printing to
//element 2 is if printer will convert to zpl
interface UsePrinterOutput {
    print: () => void;
    printerName: string;
    willConvertToZpl: boolean;
}

const usePrinter = ({
    form,
    paperSizeName,
}: {
    form: Omit<PrintRequest, 'PrinterName' | 'ShouldConvertToZpl'>;
    paperSizeName: string;
}): UsePrinterOutput => {
    const dispatch = useAppDispatch();
    const getSystemPropertiesResponse = authenticationApi.endpoints.getSystemProperties.useQuery();

    const getPrinterName = printerApi.endpoints.getAssignment.useQuery(
        paperSizeName
            ? {
                  ComputerName: getSystemPropertiesResponse.data?.HostName || '',
                  PaperSizeName: paperSizeName,
              }
            : skipToken
    );

    const [triggerPrint, printResponse] = printerApi.endpoints.print.useMutation();

    useQueryResponse({
        response: getPrinterName,
        errorMessage: 'Failed to get printer name',
    });

    useMutationResponse({
        response: printResponse,
        errorMessage: 'Failed to print',
    });

    const print = () => {
        if (!getPrinterName.data?.Name) {
            dispatch(
                setDialog({
                    content:
                        'No matching printer name for paper size found.\n Please request aid configuring printer settings',
                })
            );
            return;
        }

        triggerPrint({
            PrinterName: getPrinterName.data?.Name,
            ShouldConvertToZpl: !!getPrinterName.data?.IsThermalPrinter,
            ...form,
        });
    };

    return {
        print,
        printerName: getPrinterName.data?.Name || '',
        willConvertToZpl: !!getPrinterName.data?.IsThermalPrinter,
    };
};

export default usePrinter;
