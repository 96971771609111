import { Box, TextField } from '@mui/material';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../app/store';
import CompanyDropdownV3 from '../../../../../common/components/dropdown/CompanyDropdownV3';
import { parseTrackingNumberV2 } from '../../../../../common/utils/typedCommonUtils';
import orderLookupApi from '../../../../orderLookup/orderLookupApi';
import textFieldFocus from '../../../../rapidQc/utils/textFieldFocus';
import rmaApi from '../../../rmaApi';
import { ParentRma, ReceivedItem } from '../../../rmaModels';
import { setParentRma, setReset, setRma } from '../../../rmaSlice';
import { submitOnEnterKeyDown } from '../../../utilities';

export const CreateRmaInfoForm = ({
    setAddedItems,
    showError = false,
    setShowError,
}: {
    setAddedItems: (items: ReceivedItem[]) => void;
    showError?: boolean;
    setShowError: (show: boolean) => void;
}) => {
    const rma = useAppSelector(state => state.rma.rma);
    const dispatch = useDispatch();
    const trackingTextfieldRef = useRef<HTMLInputElement>(null);
    const [trackingNumber, setTrackingNumber] = React.useState(
        !!rma?.TrackingNumbers && rma.TrackingNumbers.length > 0 ? rma.TrackingNumbers[0] : ''
    );

    const helperText = !trackingNumber
        ? 'Please scan tracking number.'
        : 'Press ENTER to check for existing outbound order.';

    const [
        triggerGetOrderDetails,
        getOrderDetailsResponse,
    ] = orderLookupApi.endpoints.getOrderDetailsByTracking.useLazyQuery();

    const [triggerGetParentRma, rmaParentResponse] = rmaApi.endpoints.getParentRma.useLazyQuery();

    const handleTrackingNumberInput = () => {
        if (!rma?.Client || !trackingNumber) {
            return;
        }
        const parsedTracking = parseTrackingNumberV2(trackingNumber.trim()) || trackingNumber.trim();
        triggerGetParentRma({ tracking: parsedTracking });
    };

    const hasExistingParentRma = (parentRma: ParentRma) => {
        return parentRma.Client == rma?.Client;
    };

    const newTrackingNumberActions = () => {
        setTrackingNumber(parseTrackingNumberV2(trackingNumber.trim()) || trackingNumber.trim());
        if (!!rma?.Client) {
            triggerGetOrderDetails({
                momCode: rma?.Client,
                trackingNumber: parseTrackingNumberV2(trackingNumber.trim()) || trackingNumber.trim(),
            });
        }
        setShowError(false);
    };

    const handleClientSelect = async (momCode: string | null | undefined) => {
        if (!!rma) {
            await dispatch(setRma({ ...rma, Client: momCode, TrackingNumbers: [], OriginalOrderDetails: null }));
            setAddedItems([]);
            setTrackingNumber('');
            textFieldFocus({ textFieldRef: trackingTextfieldRef });
        }
    };

    const handleExistingParentRma = async (parentRma: ParentRma) => {
        await dispatch(setParentRma(parentRma));
        dispatch(setReset(true));
    };

    React.useEffect(() => {
        if (!getOrderDetailsResponse.isUninitialized && !getOrderDetailsResponse.isFetching && !!rma) {
            const originalOrderDetails =
                getOrderDetailsResponse.data?.Success && getOrderDetailsResponse.data.Data.length > 0
                    ? getOrderDetailsResponse.data.Data[0]
                    : null;
            dispatch(
                setRma({
                    ...rma,
                    OriginalOrderDetails: originalOrderDetails,
                    TrackingNumbers: [trackingNumber],
                })
            );
        }
    }, [getOrderDetailsResponse]);

    React.useEffect(() => {
        if (!rmaParentResponse.isUninitialized && !rmaParentResponse.isFetching) {
            // Attempt to get parent RMA
            if (!!rmaParentResponse.data?.Success && rmaParentResponse.data.Data.length > 0) {
                const potentialParentRma = rmaParentResponse.data.Data[0];
                if (hasExistingParentRma(potentialParentRma)) {
                    handleExistingParentRma(potentialParentRma);
                    return;
                }
            }
            newTrackingNumberActions();
        }
    }, [rmaParentResponse]);

    return (
        <Box sx={{ display: 'flex', pt: 2 }}>
            <CompanyDropdownV3
                value={rma?.Client || ''}
                textFieldProps={{ label: 'Client' }}
                sx={{ flex: 3, mr: 1 }}
                onChange={(event, company) => {
                    handleClientSelect(company?.mom);
                }}
            />

            <TextField
                inputRef={trackingTextfieldRef}
                helperText={showError ? helperText : ' '}
                error={showError}
                disabled={!rma?.Client}
                sx={{ flex: 7 }}
                label="Scan Tracking Number"
                value={trackingNumber}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    submitOnEnterKeyDown(event, handleTrackingNumberInput);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTrackingNumber(event.target.value.trim());
                }}
            />
        </Box>
    );
};
