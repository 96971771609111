import { Box, Typography } from '@mui/material';
import React from 'react';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DAYS_BACK, LIGHT_RED } from '../../constants';
import { BatchOrderChartToolTip } from './BatchOrderChartToolTip';

export const BatchOrderChartVertical = ({ chartData }: { chartData: {}[] | undefined }) => {
    if (!chartData || chartData.length <= 0)
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', m: 1 }}>
                <Typography color="warning.main">No Orders Were Created In the Last {DAYS_BACK} Days</Typography>
            </Box>
        );

    return (
        <Box sx={{ display: 'flex', mt: 3 }}>
            <Box sx={{ flex: 1, height: 150 }}>
                <ResponsiveContainer>
                    <BarChart
                        data={!!chartData ? [...chartData].reverse() : []}
                        layout="horizontal"
                        margin={{ left: -10 }}
                    >
                        <XAxis
                            dataKey="Date"
                            type="category"
                            tick={{
                                dy: 10,
                                textAnchor: 'middle',
                            }}
                        />
                        <YAxis type="number" axisLine={false} />
                        <Tooltip content={<BatchOrderChartToolTip />} />
                        <Bar dataKey="Completed" stackId="allBatched" fill="green">
                            <LabelList
                                position="center"
                                fill="white"
                                dataKey="OrdersCompletedPercentage"
                                formatter={(value: string) => `${value}%`}
                            />
                        </Bar>
                        <Bar dataKey="Not Completed" stackId="allBatched" fill={LIGHT_RED} />
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};
