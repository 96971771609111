import { Box, Button, FormControlLabel, Paper, Switch, TextField, Typography } from '@mui/material';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files-ro';
import usePrinter from '../../../common/hooks/usePrinter';
import { getContent, getFileType, toBase64 } from '../../../common/utils/typedCommonUtils';
import { PrintRequest } from '../models';
import PaperSizeAutocompleteV2 from './PaperSizeAutocompleteV2';

//https://developers.hp.com/hp-linux-imaging-and-printing/tech_docs/page_sizes
const initialForm: Omit<PrintRequest, 'PrinterName' | 'ShouldConvertToZpl'> = {
    Base64EncodedPrintData: '',
    FileType: '',
    Transformation: {
        Rotation: 0,
        Scale: {
            Multiplier: 1,
            FitToSize: {
                TargetWidth: 0, //792
                TargetHeight: 0, //612
            },
        },
        ShouldInvertOrientation: false,
    },
};

const PrintFormV2 = () => {
    const [paperSizeName, setPaperSizeName] = React.useState('');
    const [form, setForm] = React.useState(initialForm);

    const { print, printerName, willConvertToZpl } = usePrinter({ form, paperSizeName });

    return (
        <Paper sx={{ p: 3 }}>
            <form
                onSubmit={e => {
                    e.preventDefault();

                    print();
                }}
            >
                <Box sx={{ my: 2, display: 'flex' }}>
                    <PaperSizeAutocompleteV2
                        paperSizeName={paperSizeName}
                        handleInputChange={(newPaperSizeName: string, height: number, width: number) => {
                            setPaperSizeName(newPaperSizeName);
                            setForm({
                                ...form,
                                Transformation: {
                                    ...form.Transformation,
                                    Scale: {
                                        ...form.Transformation.Scale,
                                        FitToSize: {
                                            ...form.Transformation.Scale.FitToSize,
                                            TargetHeight: height,
                                            TargetWidth: width,
                                        },
                                    },
                                },
                            });
                        }}
                        key={printerName}
                    />

                    <TextField
                        value={form.Transformation.Scale.FitToSize.TargetHeight}
                        onChange={e => {
                            const newValue = Number(e.target.value) ? Number(e.target.value) : undefined;

                            setForm({
                                ...form,
                                Transformation: {
                                    ...form.Transformation,
                                    Scale: {
                                        ...form.Transformation.Scale,
                                        FitToSize: {
                                            ...form.Transformation.Scale.FitToSize,
                                            TargetHeight: newValue,
                                        },
                                    },
                                },
                            });
                        }}
                        type="number"
                        label="Height (hundreths of an inch)"
                        sx={{ mx: 1, flex: 1 }}
                    />

                    <TextField
                        value={form.Transformation.Scale.FitToSize.TargetWidth}
                        onChange={e => {
                            const newValue = Number(e.target.value) ? Number(e.target.value) : undefined;

                            setForm({
                                ...form,
                                Transformation: {
                                    ...form.Transformation,
                                    Scale: {
                                        ...form.Transformation.Scale,
                                        FitToSize: {
                                            ...form.Transformation.Scale.FitToSize,
                                            TargetWidth: newValue,
                                        },
                                    },
                                },
                            });
                        }}
                        type="number"
                        label="Width (hundreths of an inch)"
                        sx={{ mx: 1, flex: 1 }}
                    />
                </Box>
                <Box sx={{ my: 2, display: 'flex' }}>
                    <TextField
                        value={form.Transformation.Rotation}
                        onChange={e => {
                            const newValue = Number(e.target.value);
                            setForm({
                                ...form,
                                Transformation: { ...form.Transformation, Rotation: newValue },
                            });
                        }}
                        type="number"
                        label="Rotation"
                        sx={{ mx: 1, flex: 1 }}
                    />

                    <TextField
                        value={form.Transformation.Scale.Multiplier}
                        onChange={e => {
                            const newValue = Number(e.target.value);
                            setForm({
                                ...form,
                                Transformation: {
                                    ...form.Transformation,
                                    Scale: {
                                        ...form.Transformation.Scale,
                                        Multiplier: newValue,
                                    },
                                },
                            });
                        }}
                        type="number"
                        label="Scale Multiplier"
                        sx={{ mx: 1, flex: 1 }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={form.Transformation.ShouldInvertOrientation}
                                onChange={e => {
                                    const newValue = e.target.checked;
                                    setForm({
                                        ...form,
                                        Transformation: {
                                            ...form.Transformation,
                                            ShouldInvertOrientation: newValue,
                                        },
                                    });
                                }}
                            />
                        }
                        label="Inverse Orientation"
                        sx={{ m: 1 }}
                    />
                </Box>
                <Box sx={{ display: 'flex', my: 2 }}>
                    <TextField
                        value={form.FileType}
                        onChange={e => {
                            const newValue = e.target.value;
                            setForm({ ...form, FileType: newValue });
                        }}
                        label="File Type"
                        sx={{ mx: 1 }}
                    />

                    <TextField
                        value={form.Base64EncodedPrintData}
                        onChange={e => {
                            const newValue = e.target.value;
                            setForm({ ...form, Base64EncodedPrintData: newValue });
                        }}
                        required
                        label="Data"
                        sx={{ flex: 1, mx: 1 }}
                    />

                    <FileUploader
                        handleChange={async (file: File) => {
                            const base64Encoded = await toBase64(file);

                            setForm({
                                ...form,
                                FileType: getFileType(base64Encoded),
                                Base64EncodedPrintData: getContent(base64Encoded),
                            });
                        }}
                    />
                </Box>
                <Button type="submit" variant="contained" sx={{ m: 2, flex: 1, width: '100%' }}>
                    Print
                </Button>
                <Typography color={!!printerName ? 'primary' : 'error'}>
                    Printing to: {printerName || 'invalid printer name'}
                </Typography>
                <Typography>Will convert to ZPL: {willConvertToZpl ? 'true' : 'false'}</Typography>
            </form>
        </Paper>
    );
};

export default PrintFormV2;
