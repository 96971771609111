import { Box, Typography } from '@mui/material';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DAYS_BACK, LIGHT_RED } from '../../constants';
import { BatchOrderChartToolTip } from './BatchOrderChartToolTip';

const BAR_WIDWTH = 70;

export const BatchOrderChartHorizontal = ({ chartData }: { chartData: {}[] | undefined }) => {
    const chartHeight = 35 + (!!chartData ? 100 * chartData.length : 100);

    if (!chartData || chartData.length <= 0) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', m: 5 }}>
                <Typography color="warning.main">No Orders Were Created In the Last {DAYS_BACK} Days</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1, height: chartHeight }}>
                <ResponsiveContainer>
                    <BarChart data={chartData} layout="vertical" margin={{ left: -50 }}>
                        <XAxis type="number" axisLine={false} orientation="top" />
                        <YAxis
                            dataKey="DateYear"
                            type="category"
                            tick={{
                                dx: 20,
                                dy: -45,
                                textAnchor: 'start',
                            }}
                        />
                        <Tooltip content={<BatchOrderChartToolTip />} />
                        <Bar barSize={BAR_WIDWTH} dataKey="Completed" stackId="allBatched" fill="green">
                            <LabelList
                                position="center"
                                fill="white"
                                dataKey="OrdersCompletedPercentage"
                                formatter={(value: string) => `${value}%`}
                            />
                        </Bar>
                        <Bar barSize={BAR_WIDWTH} dataKey="Not Completed" stackId="allBatched" fill={LIGHT_RED} />
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};
