import { Autocomplete, TextField } from '@mui/material';
import { GridRenderEditCellParams, GridTreeNodeWithRender, useGridApiContext } from '@mui/x-data-grid';
import React from 'react';
import { GetPrintPropertiesResponse } from '../models';

//https://mui.com/x/react-data-grid/custom-columns/

export function EditPaperName(
    props: GridRenderEditCellParams<any, GetPrintPropertiesResponse, string, GridTreeNodeWithRender>
) {
    const { id, value, field } = props;

    const options = ('valueOptions' in props.colDef ? props.colDef.valueOptions : []) as GetPrintPropertiesResponse[];

    const matchingValue = options.find((option: any) => option.PaperName === value);

    const apiRef = useGridApiContext();

    const handleChange = async (
        event: React.SyntheticEvent<Element, Event>,
        newValue: GetPrintPropertiesResponse | string | null
    ) => {
        const newPaperName = typeof newValue === 'string' ? newValue : newValue?.PaperName || '';
        const newWidth = typeof newValue === 'string' ? +newValue : newValue?.Width || 0;
        const newHeight = typeof newValue === 'string' ? +newValue : newValue?.Height || 0;

        await apiRef.current.setEditCellValue({ id, field, value: newPaperName }, event);

        if (newWidth) {
            await apiRef.current.setEditCellValue({
                id: props.id,
                field: 'WidthInInches',
                value: newWidth / 100,
            });

            apiRef.current.stopCellEditMode({ id, field: 'WidthInInches' });
        }

        if (newHeight) {
            await apiRef.current.setEditCellValue({
                id: props.id,
                field: 'HeightInInches',
                value: newHeight / 100,
            });

            apiRef.current.stopCellEditMode({ id, field: 'HeightInInches' });
        }

        apiRef.current.stopCellEditMode({ id, field });
    };

    return (
        <Autocomplete
            value={matchingValue || value}
            onChange={handleChange}
            options={options}
            getOptionLabel={option => (typeof option === 'string' ? option : option.PaperName)}
            fullWidth
            freeSolo
            renderInput={params => <TextField {...params} fullWidth />}
        />
    );
}
