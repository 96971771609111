import { ItemReceivedDisposition, ReceivedItem, ReceivedItemUnitDetail, Rma } from './rmaModels';

export const NEW_STATUS = 'NEW';
export const COMPLETE_STATUS = 'COMPLETE';
export const ERROR_STATUS = 'ERROR';
export const NEW_STATUS_ID = 1;
export const COMPLETE_STATUS_ID = 2;
export const ERROR_STATUS_ID = 3;
export const RMA_STATUS: { [key: number]: string } = { 1: NEW_STATUS, 2: COMPLETE_STATUS, 3: ERROR_STATUS };

export const SKU_IMG_HEIGHT = { xs: 233, md: 167 };
export const SKU_IMG_WIDTH = { xs: 370, md: 260 };

export const BULK_UNIT_THRESHOLD_QTY = 10;
export const DISPOSITION_ASTOCK_ID = 1;
export const DISPOSITION_DAMAGED = 3;
export const DISPOSITION_OTHER_ID = 4;
export const DISPOSITION_OTHER_LABEL = 'Other';
export const MISSING_SERIAL_REASON_OTHER_ID = 3;
export const VALID_DISPOSITION_IDS = [DISPOSITION_ASTOCK_ID, DISPOSITION_DAMAGED];

export const SERIAL_QTY_LIMIT_PER_UNIT = 1;
export const CLIENT_CODE_REGEX_FROM_REF_PO = /^[A-Za-z]{3,4}/;

export const NEW_RECEIVED_ITEM: ReceivedItem = {
    Id: null,
    RmaId: null,
    Quantity: null,
    ExpectedItemId: null,
    Disposition: null,
    NotFound: false,
    UnknownItem: false,
    Serials: [],
    ItemUnitDetails: [],
    Sku: null,
    Upc: null,
    SerialVerify: null,
    IsSerialized: false,
    Description: null,
    ValidToSubmit: false,
};

export const NEW_RECEIVED_ITEM_UNIT_DETAIL: ReceivedItemUnitDetail = {
    SerialNotes: null,
    DispositionNotes: null,
    Serials: [],
    Disposition: null,
    MissingSerialReason: null,
    ValidToSubmit: false,
};

export const NEW_RMA: Rma = {
    Id: null,
    Client: null,
    TrackingNumbers: [],
    AltRmaNumber: null,
    RmaNumber: null,
    ReceivedItems: [],
    ExpectedItems: [],
    Status: NEW_STATUS_ID,
    Notes: null,
    DateAdded: null,
    DateUpdated: null,
    ProcessedBy: null,
    ProcessedOrderId: null,
    OriginalOrderDetails: null,
    ParentRmaId: null,
};

export const DISPOSITION_OTHER: ItemReceivedDisposition = {
    Id: DISPOSITION_OTHER_ID,
    Label: DISPOSITION_OTHER_LABEL,
};
