import { Box, Button, Chip, Fade, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { ReceivedItem, ReceivedItemUnitDetail } from '../../rmaModels';
import { checkItemUnitReadyToSubmit, hasSubmitted } from '../../utilities';
import { ReceivedItemUnitDetailIndividualFormDisposition } from './ReceivedItemUnitDetailIndividualFormDisposition';
import { ReceivedItemUnitDetailIndividualFormSerial } from './ReceivedItemUnitDetailIndividualFormSerial';
import { useAppSelector } from '../../../../app/store';

export const ReceivedItemUnitDetailIndividualForm = React.memo(
    ({
        unitIndex,
        editingItem,
        setEditingItem,
        showErrors = false,
        hideDialogTitle = false,
    }: {
        unitIndex: number;
        editingItem: ReceivedItem;
        setEditingItem: (item: ReceivedItem | ((prevItem: ReceivedItem) => ReceivedItem)) => void;
        showErrors?: boolean;
        hideDialogTitle?: boolean;
    }) => {
        const rma = useAppSelector(state => state.rma.rma);
        const [editingItemUnit, setEditingItemUnit] = React.useState<ReceivedItemUnitDetail>(
            editingItem.ItemUnitDetails[unitIndex]
        );

        const isLastUnitOfUnexpectedItem =
            !!!editingItem.ExpectedItemId && !!editingItem.Quantity && editingItem.Quantity <= 1;

        useEffect(() => {
            const updatedUnitDetails = editingItem.ItemUnitDetails.map((unit, currentIndex) =>
                currentIndex === unitIndex
                    ? {
                          ...editingItemUnit,
                          ValidToSubmit: checkItemUnitReadyToSubmit(
                              editingItemUnit,
                              editingItem.IsSerialized,
                              editingItem.UnknownItem
                          ),
                      }
                    : unit
            );
            setEditingItem({ ...editingItem, ItemUnitDetails: updatedUnitDetails });
        }, [editingItemUnit]);

        useEffect(() => {
            const paramUnit = editingItem.ItemUnitDetails[unitIndex];
            if (JSON.stringify(paramUnit) !== JSON.stringify(editingItemUnit)) {
                setEditingItemUnit(paramUnit);
            }
        }, [editingItem.ItemUnitDetails[unitIndex]]);

        const handleDeleteUnit = () => {
            // Don't allow delete if this is the last unit and the item is unexpected
            if (isLastUnitOfUnexpectedItem) {
                return;
            }

            setEditingItem({
                ...editingItem,
                Quantity: (!!editingItem.Quantity ? editingItem.Quantity : 0) - 1,
                ItemUnitDetails: editingItem.ItemUnitDetails.filter((unit, index) => index !== unitIndex),
            });
        };

        if (!editingItemUnit) {
            return <React.Fragment />;
        }

        return (
            <Box>
                <Fade in={true} timeout={300}>
                    <Paper elevation={2} sx={{ my: 1 }}>
                        {hideDialogTitle ? (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', py: 0.5, pl: 1 }} />
                        ) : (
                            <Box
                                bgcolor="primary.main"
                                sx={{ display: 'flex', justifyContent: 'flex-start', py: 0.5, pl: 1 }}
                            >
                                <Typography color="white" variant="body2">
                                    UNIT #{unitIndex + 1}
                                </Typography>
                            </Box>
                        )}

                        <Box sx={{ my: 3 }}>
                            {!hasSubmitted(rma?.Status) && (
                                <Box sx={{ mr: 5, mt: -1, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Chip
                                        label={
                                            checkItemUnitReadyToSubmit(
                                                editingItemUnit,
                                                editingItem.IsSerialized,
                                                editingItem.UnknownItem
                                            )
                                                ? 'PROCESSED'
                                                : 'UNPROCESSED'
                                        }
                                        color={
                                            checkItemUnitReadyToSubmit(
                                                editingItemUnit,
                                                editingItem.IsSerialized,
                                                editingItem.UnknownItem
                                            )
                                                ? 'success'
                                                : 'warning'
                                        }
                                    />
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'stretch',
                                    alignContent: 'stretch',
                                    mx: 3,
                                    pb: 3,
                                }}
                            >
                                <Box sx={{ ml: 2, mr: 2, flex: 2 }}>
                                    <ReceivedItemUnitDetailIndividualFormDisposition
                                        editingItemUnit={editingItemUnit}
                                        setEditingItemUnit={setEditingItemUnit}
                                        showErrors={showErrors}
                                        unknownItem={editingItem.UnknownItem}
                                    />
                                </Box>
                                <ReceivedItemUnitDetailIndividualFormSerial
                                    editingItem={editingItem}
                                    editingItemUnit={editingItemUnit}
                                    setReceivedItemUnit={setEditingItemUnit}
                                    showErrors={showErrors}
                                />
                            </Box>
                            {!isLastUnitOfUnexpectedItem && !hasSubmitted(rma?.Status) && (
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', ml: 5 }}>
                                    <Button
                                        onClick={() => handleDeleteUnit()}
                                        variant="contained"
                                        color="error"
                                        sx={{ mb: 2.5 }}
                                    >
                                        DELETE
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Paper>
                </Fade>
            </Box>
        );
    }
);
