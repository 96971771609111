import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import {
    DataGrid,
    DataGridProps,
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridSlotProps,
    GridToolbarContainer,
    GridValidRowModel,
} from '@mui/x-data-grid';
import * as React from 'react';
import ConfirmDialog from '../Dialog/ConfirmDialog';

//If id is this value, then record is new and has not been saved to database yet
export const EDITTABLE_DATAGRID_NEW_ROW_ID = -1;

declare module '@mui/x-data-grid' {
    interface ToolbarPropsOverrides {
        newRow: any; //TODO: convert to generics
        setRows: (newRows: (oldRows: any[]) => any[]) => void;
        setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
    }
}

function EditToolbar(props: GridSlotProps['toolbar']) {
    const { initialRow, setRows, setRowModesModel } = props;

    const handleClick = () => {
        setRows(oldRows => [initialRow, ...oldRows]);
        setRowModesModel(oldModel => ({
            ...oldModel,
            [EDITTABLE_DATAGRID_NEW_ROW_ID]: { mode: GridRowModes.Edit },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add Record
            </Button>
        </GridToolbarContainer>
    );
}

interface EdittableDatagridProps<TRow extends GridValidRowModel, TCreateRow> extends DataGridProps<TRow> {
    setRows: (newRows: (oldRows: any[]) => any[]) => void;
    initialRow: TCreateRow;
    columns: GridColDef[];
    handleDeleteClick: (id: GridRowId) => void;
}

export default function EdittableDatagrid<TRow extends GridValidRowModel, TCreateRow>({
    setRows,
    initialRow,
    columns,
    handleDeleteClick,
    ...props
}: EdittableDatagridProps<TRow, TCreateRow>) {
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        deleteNewRow(id);
    };

    const deleteNewRow = (id: GridRowId) => {
        const editedRow = props?.rows?.find(row => row.Id === id);
        if (!editedRow) return;
        if (editedRow.Id === EDITTABLE_DATAGRID_NEW_ROW_ID) {
            setRows(() => props?.rows?.filter(row => row.Id !== id) || []);
        }
    };

    const [idToDelete, setIdToDelete] = React.useState<number | undefined>();

    const actionColumn: GridColDef = {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        flex: 1,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => {
                        setIdToDelete(+id);
                    }}
                    color="inherit"
                />,
            ];
        },
    };

    return (
        <>
            <DataGrid
                columns={[...columns, actionColumn]}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{ toolbar: EditToolbar }}
                slotProps={{
                    toolbar: { initialRow, setRows, setRowModesModel },
                }}
                autoHeight
                {...props}
            />

            <ConfirmDialog
                open={!!idToDelete}
                handleCancel={() => {
                    setIdToDelete(undefined);
                }}
                handleConfirm={() => {
                    if (idToDelete) {
                        handleDeleteClick(idToDelete);
                    }
                }}
                title={'Confirm Delete'}
                content={`Are you sure you want to delete Id: ${idToDelete}?`}
            />
        </>
    );
}
