import { Autocomplete, TextField } from '@mui/material';
import { PaperSizeRecord } from '../models';
import printerApi from '../printerApi';

const PaperSizeAutocompleteV2 = ({
    paperSizeName,
    handleInputChange,
}: {
    paperSizeName: string;
    handleInputChange: (paperSizeName: string, height: number, width: number) => void;
}) => {
    const getPaperSizesResponse = printerApi.endpoints.getPaperSizes.useQuery();

    return (
        <Autocomplete
            disablePortal
            options={getPaperSizesResponse.data || []}
            onChange={(event, newInputValue) => {
                if (newInputValue)
                    handleInputChange(
                        newInputValue.Name,
                        newInputValue.HeightInInches * 100,
                        newInputValue.WidthInInches * 100
                    );
            }}
            getOptionLabel={(paperSize: PaperSizeRecord) => `${paperSize.Name}`}
            sx={{ flex: 2, mx: 1 }}
            renderInput={params => <TextField {...params} label="Paper Size" />}
        />
    );
};

export default PaperSizeAutocompleteV2;
