import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useAppDispatch } from '../../../../app/store';
import usePrinter from '../../../../common/hooks/usePrinter';
import { setDialog } from '../../../notification/notificationSlice';
import { PrintRequest } from '../../../printer/models';
import relabelApi from '../../relabelApi';
interface Form {
    Sku: string;
    SerialNumber: string;
    MFGDate: Dayjs;
}

const DEFAULT_CLIENT = 'BAK';
const PAPER_SIZE_NAME = `2.00" x 0.50"`;
const SKU_OPTIONS = ['BB-02-P-WS', 'BB-51-P-WS'];
const DEFAULT_SKU = SKU_OPTIONS[0];

const BB02PWS = () => {
    const [form, setForm] = React.useState<Form>({
        Sku: DEFAULT_SKU,
        SerialNumber: '',
        MFGDate: dayjs(),
    });

    const getZpl = () => {
        const formattedDate = form.MFGDate.format('MM/YYYY');

        return `^XA
            ^FX Barcode
            ^FO10,20^BC

            ^BY2,3,100
            ^BC,20,N,N,N,N
            ^FD${form.SerialNumber}^FS

            ^FX SKU
            ^CFA,20
            ^FO10,50^FDSKU: ${form.Sku}^FS

            ^FX MFG
            ^CFA,20
            ^FO220,50^FDMFG: ${formattedDate}^FS

            ^FX SN
            ^CFA,20
            ^FO10,75^FDSN: ${form.SerialNumber}^FS

            ^XZ`;
    };

    const printForm: Omit<PrintRequest, 'PrinterName' | 'ShouldConvertToZpl'> = {
        Base64EncodedPrintData: btoa(getZpl()),
        FileType: 'ZPL',
        Transformation: {
            Rotation: 0,
            Scale: {
                Multiplier: 1,
                FitToSize: {
                    TargetWidth: 4,
                    TargetHeight: 2,
                },
            },
            ShouldInvertOrientation: false,
        },
    };

    const { print } = usePrinter({
        form: printForm,
        paperSizeName: PAPER_SIZE_NAME,
    });

    const [triggerCheckSerial, checkSerialResponse] = relabelApi.endpoints.getIsSerailInRecSN.useLazyQuery();
    const dispatch = useAppDispatch();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!getDoesSerialMatchesRegex()) {
            dispatch(setDialog({ content: 'Serial does not match the regex pattern' }));
            return;
        }

        const isSerialInRecSN = await triggerCheckSerial({
            MomCode: DEFAULT_CLIENT,
            Serial: form.SerialNumber,
            Sku: form.Sku,
        }).unwrap();

        if (!isSerialInRecSN) {
            dispatch(setDialog({ content: 'Serial was not inbounded' }));
            return;
        }

        print();
    };

    const getSerialVerifyRegexResponse = relabelApi.endpoints.getSerialVerifyRegex.useQuery({
        Client: DEFAULT_CLIENT,
        Sku: form.Sku || DEFAULT_SKU,
    });

    const getDoesSerialMatchesRegex = (): boolean => {
        if (getSerialVerifyRegexResponse.isError || !getSerialVerifyRegexResponse.data) return true;

        const regex = new RegExp(getSerialVerifyRegexResponse.data);
        const doesMatchRegex = regex.test(form.SerialNumber);

        return doesMatchRegex;
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Autocomplete
                    value={form.Sku}
                    options={SKU_OPTIONS}
                    onChange={(event, option) => {
                        setForm(oldForm => {
                            return { ...oldForm, Sku: option || '' };
                        });
                    }}
                    renderInput={params => <TextField label={'SKU'} {...params} />}
                />

                <Box sx={{ flex: 1, display: 'flex', gap: 2 }}>
                    <TextField
                        label={'Serial Number'}
                        value={form.SerialNumber}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = event.target.value.toUpperCase();
                            setForm(oldForm => {
                                return { ...oldForm, SerialNumber: newValue };
                            });
                        }}
                        required
                        sx={{ flex: 1 }}
                        name="serialNumber"
                        helperText={
                            (getSerialVerifyRegexResponse.isSuccess && getSerialVerifyRegexResponse.data) || ' '
                        }
                        error={!getDoesSerialMatchesRegex()}
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['year', 'month']}
                            format={'MMM (MM/YYYY)'}
                            label="MFG Date"
                            minDate={dayjs().subtract(5, 'year')}
                            maxDate={dayjs()}
                            value={form.MFGDate}
                            onChange={newValue => {
                                if (newValue)
                                    setForm(oldForm => {
                                        return { ...oldForm, MFGDate: newValue };
                                    });
                            }}
                        />
                    </LocalizationProvider>
                </Box>

                <Button sx={{ flex: 1 }} variant="contained" type="submit">
                    Submit
                </Button>
            </Box>
        </form>
    );
};

export default BB02PWS;
