import { GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import * as React from 'react';
import { useAppDispatch } from '../../../app/store';
import EdittableDatagrid, {
    EDITTABLE_DATAGRID_NEW_ROW_ID,
} from '../../../common/components/datagrid/EdittableDatagrid';
import useMutationResponse from '../../../common/hooks/useMutationResponse';
import authenticationApi from '../../authentication/authenticationApi';
import { setDialog } from '../../notification/notificationSlice';
import { ComputerRecord } from '../models';
import printerApi from '../printerApi';

export default function ComputerDatagrid({
    computers,
    onRowClick = () => {},
}: {
    computers: ComputerRecord[];
    onRowClick?: (id: number) => void;
}) {
    const [rows, setRows] = React.useState(computers);
    const dispatch = useAppDispatch();

    const [triggerCreate, createResponse] = printerApi.endpoints.createComputer.useMutation();
    const [triggerUpdate, updateResponse] = printerApi.endpoints.updateComputer.useMutation();
    const [triggerDelete, deleteResponse] = printerApi.endpoints.deleteComputer.useMutation();

    useMutationResponse({ response: createResponse, errorMessage: `Failed to create computer` });
    useMutationResponse({ response: updateResponse, errorMessage: `Failed to update computer` });
    useMutationResponse({ response: deleteResponse, errorMessage: `Failed to delete computer` });

    const getSystemPropertiesResponse = authenticationApi.endpoints.getSystemProperties.useQuery();

    const handleDeleteClick = (id: GridRowId) => {
        const matchingRow = rows.find(row => row.Id === id);
        if (!matchingRow) return;

        triggerDelete(matchingRow.Id);
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const matchingRow = { ...newRow } as ComputerRecord;

        if (!matchingRow) return matchingRow;

        if (!matchingRow.Name) {
            dispatch(setDialog({ content: 'Name field is required' }));
            return matchingRow;
        }

        setRows(rows.map(row => (row.Id === newRow.Id ? matchingRow : row)));

        if (newRow.Id === EDITTABLE_DATAGRID_NEW_ROW_ID) triggerCreate({ Name: matchingRow.Name });
        else triggerUpdate({ Id: matchingRow.Id, Name: matchingRow.Name });

        return matchingRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'Id',
            headerName: 'Id',
            type: 'number',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (value: any) => (value === EDITTABLE_DATAGRID_NEW_ROW_ID ? '-' : value),
        },
        {
            field: 'Name',
            headerName: 'Computer Name',
            flex: 3,
            editable: true,
        },
    ];

    return (
        <EdittableDatagrid
            rows={rows}
            setRows={setRows}
            initialRow={{ Id: EDITTABLE_DATAGRID_NEW_ROW_ID, Name: getSystemPropertiesResponse.data?.HostName || '' }}
            getRowId={row => row.Id}
            columns={columns}
            handleDeleteClick={handleDeleteClick}
            processRowUpdate={processRowUpdate}
            onRowClick={(params, event) => onRowClick(+params.id)}
        />
    );
}
