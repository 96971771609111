import { AccordionSummary, Box, Button, Chip } from '@mui/material';
import React, { useState } from 'react';
import { ExpectedItem, ReceivedItem } from '../../rmaModels';
import ReadOnlyTextField from '../ReadOnlyTextField';

export const ReceivedItemSummaryPanelAccordianSummary = ({
    receivedItem,
    expectedItem,
    qtyMissing,
    qtyExtra,
}: {
    receivedItem: ReceivedItem;
    expectedItem?: ExpectedItem;
    qtyMissing: number;
    qtyExtra: number;
}) => {
    const missingSerials = () => {
        if (!receivedItem.IsSerialized) {
            return 0;
        }
        return receivedItem.ItemUnitDetails.filter(unit => unit.Serials.length === 0).length;
    };
    const missingSerialMsg = `MISSING ${missingSerials()} SERIAL${missingSerials() > 1 ? 'S' : ''}`;
    const [accordianOpen, setAccordianOpen] = useState(false);

    return (
        <AccordionSummary
            aria-controls="unit-content"
            id="unit-header"
            onClick={() => {
                setAccordianOpen(!accordianOpen);
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Box sx={{ display: 'flex', flex: 1 }}>
                    {!!expectedItem ? (
                        <React.Fragment>
                            <ReadOnlyTextField
                                variant="filled"
                                value={expectedItem.Quantity}
                                label="EXPECTED QTY"
                                sx={{ flex: 1, m: 1 }}
                            />
                            <ReadOnlyTextField
                                variant="filled"
                                value={receivedItem.Quantity}
                                label="RECEIVED QTY"
                                sx={{ flex: 1, m: 1 }}
                            />
                        </React.Fragment>
                    ) : (
                        <ReadOnlyTextField
                            variant="filled"
                            sx={{ flex: 0.5, m: 1 }}
                            label="RECEIVED QTY"
                            value={receivedItem.Quantity}
                        />
                    )}

                    {!!qtyMissing ? (
                        !!(qtyMissing > 0) ? (
                            <ReadOnlyTextField
                                variant="filled"
                                sx={{ flex: 1, m: 1 }}
                                label={'MISSING QTY'}
                                value={qtyMissing}
                                multiline={true}
                                InputLabelProps={{
                                    sx: {
                                        color: 'red',
                                    },
                                }}
                            />
                        ) : (
                            <ReadOnlyTextField
                                variant="filled"
                                sx={{ flex: 1, m: 1 }}
                                label="EXTRA QTY"
                                value={qtyExtra}
                                InputLabelProps={{
                                    sx: {
                                        color: 'primary.main',
                                    },
                                }}
                            />
                        )
                    ) : (
                        <Box sx={{ flex: 1, m: 1 }}></Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', mt: 1, mx: 1 }}>
                    {missingSerials() > 0 && !receivedItem.UnknownItem ? (
                        <Chip sx={{ mr: 2 }} label={missingSerialMsg} color="error" />
                    ) : (
                        <Box />
                    )}
                    <Button size="small" variant="contained">
                        {accordianOpen ? 'HIDE DETAILS' : 'SHOW DETAILS'}
                    </Button>
                </Box>
            </Box>
        </AccordionSummary>
    );
};
