import { GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import * as React from 'react';
import { useAppDispatch } from '../../../app/store';
import EdittableDatagrid, {
    EDITTABLE_DATAGRID_NEW_ROW_ID,
} from '../../../common/components/datagrid/EdittableDatagrid';
import useMutationResponse from '../../../common/hooks/useMutationResponse';
import { AssignmentRecord, UpdateAssignmentRequest } from '../models';
import printerApi from '../printerApi';
import { EditComputer } from './assignment/EditComputer';
import { EditPaperSize } from './assignment/EditPaperSize';
import { EditPrinter } from './assignment/EditPrinter';

export default function AssignmentDatagrid({ assignments }: { assignments: AssignmentRecord[] }) {
    const [rows, setRows] = React.useState(assignments);
    const dispatch = useAppDispatch();

    const getComputersResponse = printerApi.endpoints.getComputers.useQuery();
    const getPrintersResponse = printerApi.endpoints.getPrinters.useQuery();
    const getPaperSizesResponse = printerApi.endpoints.getPaperSizes.useQuery();

    const initialRow: UpdateAssignmentRequest = {
        Id: EDITTABLE_DATAGRID_NEW_ROW_ID,
        ComputerId: getComputersResponse?.data?.[0]?.Id || -1,
        PrinterId: getPrintersResponse?.data?.[0]?.Id || -1,
        PaperSizeId: getPaperSizesResponse?.data?.[0]?.Id || -1,
    };

    const [triggerCreate, createResponse] = printerApi.endpoints.createAssignment.useMutation();
    const [triggerUpdate, updateResponse] = printerApi.endpoints.updateAssignment.useMutation();
    const [triggerDelete, deleteResponse] = printerApi.endpoints.deleteAssignment.useMutation();

    useMutationResponse({ response: createResponse, errorMessage: `Failed to create assignment` });
    useMutationResponse({ response: updateResponse, errorMessage: `Failed to update assignment` });
    useMutationResponse({ response: deleteResponse, errorMessage: `Failed to delete assignment` });

    const handleDeleteClick = (id: GridRowId) => {
        const matchingRow = rows.find(row => row.Id === id);
        if (!matchingRow) return;

        triggerDelete(matchingRow.Id);
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const matchingRow = { ...newRow } as AssignmentRecord;

        if (!matchingRow) return matchingRow;

        setRows(rows.map(row => (row.Id === newRow.Id ? matchingRow : row)));

        if (newRow.Id === EDITTABLE_DATAGRID_NEW_ROW_ID) triggerCreate(matchingRow);
        else triggerUpdate(matchingRow);

        return matchingRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'Id',
            headerName: 'Id',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (value: any) => (value === EDITTABLE_DATAGRID_NEW_ROW_ID ? '-' : value),
        },
        {
            field: 'ComputerId',
            headerName: 'Computer',
            flex: 4,
            editable: true,
            type: 'singleSelect',
            valueOptions: getComputersResponse.data || [],
            getOptionValue: (value: any) => value?.Id,
            getOptionLabel: (value: any) => value?.Name,
            renderEditCell: props => <EditComputer {...props} />,
        },
        {
            field: 'PrinterId',
            headerName: 'Printer',
            flex: 4,
            editable: true,
            type: 'singleSelect',
            valueOptions: getPrintersResponse.data || [],
            getOptionValue: (value: any) => value?.Id,
            getOptionLabel: (value: any) => value?.Name,
            renderEditCell: props => <EditPrinter {...props} />,
        },
        {
            field: 'PaperSizeId',
            headerName: 'Paper Size',
            flex: 2,
            editable: true,
            type: 'singleSelect',
            valueOptions: getPaperSizesResponse.data || [],
            getOptionValue: (value: any) => value?.Id,
            getOptionLabel: (value: any) => value?.Name,
            renderEditCell: props => <EditPaperSize {...props} />,
        },
    ];

    return (
        <EdittableDatagrid
            rows={rows}
            setRows={setRows}
            initialRow={initialRow}
            getRowId={row => row.Id}
            columns={columns}
            handleDeleteClick={handleDeleteClick}
            processRowUpdate={processRowUpdate}
        />
    );
}
