import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React, { Dispatch, SetStateAction, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../../app/store';
import { setDialog } from '../../../../../notification/notificationSlice';
import receiversApi from '../../../../oldReceiversApi';
import { Stock, SubmissionForm, SubmissionFormItem } from '../../../../receiversModels';
import ItemCard from './ItemCard';

interface ItemsProps {
    form: SubmissionForm;
    setForm: Dispatch<SetStateAction<SubmissionForm>>;
    shouldFormShowError: boolean;
}

const Items: React.FC<ItemsProps> = memo(({ form, setForm, shouldFormShowError }) => {
    const momCode = useAppSelector(state => state.oldReceivers.momCode);
    const { data: stocks } = receiversApi.endpoints.getReceiverStocksDeprecated.useQuery(momCode ? momCode : skipToken);

    const isDisabled = form.Played;
    const dispatch = useDispatch();
    const [itemToBeAdded, setItemToBeAdded] = React.useState<Stock | null>(null);
    React.useEffect(() => {
        return setItemToBeAdded(null);
    }, [stocks]);

    const handleItemAddButtonClicked = () => {
        if (itemToBeAdded) {
            let stockToBeAdded: Stock | undefined = stocks?.find((stock: Stock) => stock.Sku === itemToBeAdded.Sku);

            if (stockToBeAdded === undefined) {
                dispatch(setDialog({ content: `Choose a valid stock from dropdown`, title: `Not Valid Stock` }));
                return;
            }
            const itemToBeAddedConverted: SubmissionFormItem = {
                Item: stockToBeAdded.Sku,
                LineId: null,
                Descript: stockToBeAdded.Description,
                PlQty: 0,
                RecQty: 0,
                RecCrtncount: 0,
                RecCaseqty: 0,
                RecPartial: 0,
                Notes: '',
            };
            if (form.Items.find(item => item.Item === itemToBeAddedConverted.Item)) {
                dispatch(setDialog({ content: `Choose different item`, title: `Stock already contained` }));
                return;
            }
            setForm(
                (oldForm: SubmissionForm): SubmissionForm => {
                    return { ...oldForm, Items: [...form.Items, itemToBeAddedConverted] };
                }
            );
        }
    };

    const itemsBoxList = form.Items?.map((item: SubmissionFormItem, index: number) => (
        <ItemCard item={item} setForm={setForm} key={index} itemIndex={index} isDisabled={isDisabled} />
    ));

    const shouldShowItemError = form.Items?.length <= 0 && shouldFormShowError;

    return (
        <Box sx={{ m: 3 }}>
            <Typography variant="h4">{`Items ${isDisabled ? '(Already Played- is disabled)' : ''}`}</Typography>
            <Box sx={{ display: 'flex' }}>
                <Autocomplete
                    disablePortal
                    autoSelect
                    data-testid={'items-dropdown-autocomplete'}
                    options={[...new Map(stocks?.map(item => [item['Sku'], item])).values()]} //gets unique, but only needed for FRAN
                    getOptionLabel={(stock: Stock) =>
                        `${stock.Sku} ${stock.Description} (UPC: ${stock.Upc}) (Available in stock: ${stock.Available})`
                    }
                    value={itemToBeAdded}
                    onChange={(event: any, newValue: Stock | null) => {
                        setItemToBeAdded(newValue);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={stocks ? 'Item' : 'Loading...'}
                            error={shouldShowItemError}
                            helperText={shouldShowItemError ? 'At least one item required' : ' '}
                        />
                    )}
                    sx={{ mt: 2, flex: 1 }}
                    isOptionEqualToValue={(option, value) => value === option}
                />
                <Button
                    variant="contained"
                    sx={{ m: 3 }}
                    onClick={handleItemAddButtonClicked}
                    color="secondary"
                    disabled={isDisabled}
                >
                    Add Item
                </Button>
            </Box>
            {itemsBoxList}
        </Box>
    );
}, arePropsEqual);

function arePropsEqual(oldProps: ItemsProps, newProps: ItemsProps) {
    return oldProps.form.Items === newProps.form.Items && oldProps.shouldFormShowError === newProps.shouldFormShowError;
}

export default Items;
