import { Box, Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import useNotificationByMutationResponseV2 from '../../../../common/hooks/useNotificationByMutationResponseV2';
import { setDialog, setSnackbar } from '../../../notification/notificationSlice';
import rmaApi from '../../rmaApi';
import { PairedRmaItem, PostReceivedItemsResponse, ReceivedItem, Rma } from '../../rmaModels';
import { setReset } from '../../rmaSlice';
import { hasSubmitted } from '../../utilities';
import { ExpectedRmaPairsDisplay } from './ExpectedRmaPairsDisplay';
import { UnexpectedItemsDisplay } from './UnexpectedItemsDisplay';

export const RecievedItemsSummaryDisplay = ({
    pairedRmaItems,
    unexpectedItems,
    handleOpenDialog,
}: {
    pairedRmaItems: PairedRmaItem[];
    unexpectedItems: ReceivedItem[];
    handleOpenDialog: (openState: boolean) => void;
}) => {
    const rma = useAppSelector(state => state.rma.rma);
    const { username } = useAppSelector(state => state.authentication);
    const dispatch = useDispatch();
    const isNewRma = !rma?.Id;
    const [postReceivedItems, postReceivedItemsResponse] = rmaApi.endpoints.postRecievedItems.useMutation();
    const [postNewRma, postNewRmaResponse] = rmaApi.endpoints.postNewRma.useMutation();

    const submitExistingRmaSummaryHandler = () => {
        if (!rma?.Client || !rma?.Id) {
            return;
        }

        const allReceivedItems = pairedRmaItems.map(pair => pair.ReceivedItem).concat(unexpectedItems);
        postReceivedItems({
            receivedItems: allReceivedItems,
            username: username,
            momCode: rma?.Client,
        });
    };

    const submitNewRmaSummaryHandler = () => {
        if (!rma?.Client || !!rma?.Id) {
            return;
        }

        const submitRma: Rma = { ...rma, ReceivedItems: unexpectedItems };
        postNewRma({
            rma: submitRma,
            username: username,
            momCode: rma?.Client,
        });
    };

    useNotificationByMutationResponseV2({
        response: postReceivedItemsResponse,
    });

    useNotificationByMutationResponseV2({
        response: postNewRmaResponse,
    });

    React.useEffect(() => {
        if (!postReceivedItemsResponse.isUninitialized && !postReceivedItemsResponse.isLoading) {
            resolveSubmissionResponse(postReceivedItemsResponse.data);
        }
    }, [postReceivedItemsResponse.isLoading, postReceivedItemsResponse.data]);

    React.useEffect(() => {
        if (!postNewRmaResponse.isUninitialized && !postNewRmaResponse.isLoading) {
            resolveSubmissionResponse(postNewRmaResponse.data);
        }
    }, [postNewRmaResponse.isLoading, postNewRmaResponse.data]);

    const resolveSubmissionResponse = (response: PostReceivedItemsResponse | null | undefined) => {
        if (response?.Success) {
            dispatch(setReset(true));
            handleOpenDialog(false);
            dispatch(
                setSnackbar({
                    content: 'RMA REVIEW SUBMITTED',
                })
            );
        } else {
            dispatch(
                setDialog({
                    title: 'COULD NOT SUBMIT',
                    content: response?.Error || 'UNKNOWN ERROR, PLEASE CONTACT ADMIN',
                })
            );
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <ExpectedRmaPairsDisplay pairedRmaItems={pairedRmaItems} />
            <UnexpectedItemsDisplay unexpectedItems={unexpectedItems} />

            <Box sx={{ display: 'flex' }}>
                <Button
                    size="large"
                    sx={{ flex: 1 }}
                    onClick={() => {
                        handleOpenDialog(false);
                    }}
                >
                    GO BACK
                </Button>
                {!hasSubmitted(rma?.Status) &&
                    (isNewRma ? (
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{ flex: 4 }}
                            onClick={submitNewRmaSummaryHandler}
                        >
                            CONFIRM AND SUBMIT NEW RMA
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            sx={{ flex: 4 }}
                            onClick={submitExistingRmaSummaryHandler}
                        >
                            CONFIRM AND SUBMIT
                        </Button>
                    ))}
            </Box>
        </Box>
    );
};
