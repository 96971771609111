import { DREAM_URL, RO_AGENT_URL, ROAPI_URL } from './api_constants';

export const prependRoapiUrl = (url: string): string => {
    return `${ROAPI_URL}${url}`;
};

export const prependDreamUrl = (url: string): string => {
    return `${DREAM_URL}${url}`;
};

export const prependRoAgentUrl = (url: string): string => {
    return `${RO_AGENT_URL}${url}`;
};
