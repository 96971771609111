import { Box, Divider, Typography } from '@mui/material';
import { TooltipProps } from 'recharts';

const LEGEND_FONT_SIZE = { sm: '0.7rem', md: '1.0rem' };

export const BatchOrderChartToolTip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const {
            Completed,
            Manifested,
            Total,
            IncompleteBatches,
            TotalBatches,
            Date,
            OrdersCompletedPercentage,
            BatchesCompletedPercentage,
        } = payload[0].payload;
        return (
            <Box style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
                <Typography sx={{ fontSize: LEGEND_FONT_SIZE }}>Date: {Date}</Typography>
                <Typography sx={{ fontSize: LEGEND_FONT_SIZE }}>Orders Manifested: {Manifested}</Typography>
                <Divider />
                <Typography color="success.main" sx={{ fontSize: LEGEND_FONT_SIZE }}>
                    Batches Completed: {TotalBatches - IncompleteBatches} ({BatchesCompletedPercentage}%)
                </Typography>
                <Typography sx={{ fontSize: LEGEND_FONT_SIZE }}>Total Batches: {TotalBatches}</Typography>
                <Divider />
                <Typography color="success.main" sx={{ fontSize: LEGEND_FONT_SIZE }}>
                    Orders Completed: {Completed} ({OrdersCompletedPercentage}%)
                </Typography>
                <Typography sx={{ fontSize: LEGEND_FONT_SIZE }}>Total Orders: {Total}</Typography>
            </Box>
        );
    }
    return null;
};
