import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useAppSelector } from '../../../../app/store';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { ChildRmasDataGrid } from './ChildRmasDataGrid';
import { CreateRmaDialog } from '../ReceivedItems/CreateNewRma/CreateRmaDialog';
import { setParentRma, setReset, setRma } from '../../rmaSlice';
import { useDispatch } from 'react-redux';
import { NEW_RMA } from '../../constants';

export const ParentRmaDialog = React.memo(
    ({
        handleDialogToggle,
        showDialog = false,
    }: {
        handleDialogToggle: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const dispatch = useDispatch();
        const [newRmaDialog, setNewRmaDialog] = React.useState(false);
        const closeCreateRmaDialog = useAppSelector(state => state.rma.reset); // Set to true after confirming summary submission
        const parentRma = useAppSelector(state => state.rma.parentRma);
        const trackingNumber =
            !!parentRma && parentRma.TrackingNumbers.length > 0 ? parentRma?.TrackingNumbers[0] : '(None)';

        React.useEffect(() => {
            if (closeCreateRmaDialog) {
                setNewRmaDialog(false);
                dispatch(setReset(false));
            }
        }, [closeCreateRmaDialog]);

        return (
            <React.Fragment>
                <CreateRmaDialog showDialog={newRmaDialog} handleOpenDialog={setNewRmaDialog} />
                <Dialog
                    open={showDialog}
                    fullWidth
                    maxWidth="md"
                    PaperProps={{
                        sx: { backgroundColor: grey[100] },
                    }}
                >
                    <DialogTitle color="white" bgcolor="primary.main" align="center">
                        <Typography fontWeight={'fontWeightMedium'} variant="h5">
                            PROCESSED RMAS FOR TRACKING NUMBER
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, px: 1 }}>
                            <Typography variant="h6">CLIENT: {parentRma?.Client}</Typography>
                            <Typography variant="h6">TRACKING: {trackingNumber}</Typography>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                            <ChildRmasDataGrid />
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Button
                                onClick={() => {
                                    dispatch(
                                        setRma({
                                            ...NEW_RMA,
                                            Client: parentRma?.Client,
                                            ParentRmaId: parentRma?.Id,
                                        })
                                    );
                                    setNewRmaDialog(true);
                                }}
                                size="large"
                                sx={{ flex: 1, mt: 2 }}
                                variant="contained"
                            >
                                ADD RMA TO THIS GROUP
                            </Button>
                        </Box>
                        <DialogCloseButton
                            handleClose={() => {
                                handleDialogToggle(false);
                                dispatch(setParentRma(null));
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
);
