import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { ExpectedItem, ReceivedItem, ReceivedItemUnitDetail } from '../../../rmaModels';
import { hasSubmitted, serialDisplay } from '../../../utilities';
import { ReceivedItemUnitDetailIndividualFormDialog } from '../ReceivedItemUnitDetailIndividualFormDialog';
import { useAppSelector } from '../../../../../app/store';

interface GridUnit extends ReceivedItemUnitDetail {
    datagridId: number;
    index: number;
}

const ROW_TOP_MARGIN = 1.5;

export const ReceivedItemUnitDetailBulkForm = React.memo(
    ({
        expectedItem,
        editingItem,
        setEditingItem,
    }: {
        expectedItem?: ExpectedItem;
        editingItem: ReceivedItem;
        setEditingItem: (item: ReceivedItem | ((prevItem: ReceivedItem) => ReceivedItem)) => void;
    }) => {
        const rma = useAppSelector(state => state.rma.rma);
        const [individualUnitDialog, setIndividualUnitDialog] = React.useState(false);
        const [currentUnitIndex, setCurrentUnitIndex] = React.useState(0);

        const gridUnitsData: GridUnit[] =
            editingItem.ItemUnitDetails.map((unit, index) => {
                const gridUnit: GridUnit = { ...unit, datagridId: index + 1, index: index };
                return gridUnit;
            }) || [];

        const columns: GridColDef[] = [
            {
                align: 'center',
                headerAlign: 'center',
                field: 'datagridId',
                headerName: '#',
                flex: 1,
                type: 'number',
                renderCell: (params: any) => (
                    <Box sx={{ mt: ROW_TOP_MARGIN }}>
                        <Typography>{params?.value}</Typography>
                    </Box>
                ),
            },
            {
                align: 'left',
                headerAlign: 'left',
                field: 'Disposition',
                headerName: 'Disposition',
                flex: 4,
                valueGetter: (value: any) => {
                    return value?.Label || '-';
                },
                renderCell: params => (
                    <Box sx={{ mt: ROW_TOP_MARGIN }}>
                        <Typography>{params.value}</Typography>
                    </Box>
                ),
            },
            {
                align: 'left',
                headerAlign: 'left',
                field: 'DispositionNotes',
                headerName: 'Disposition Notes',
                flex: 5,
                valueGetter: (value: any) => {
                    return value || '-';
                },
                renderCell: params => (
                    <Box sx={{ mt: ROW_TOP_MARGIN }}>
                        <Typography>{params.value}</Typography>
                    </Box>
                ),
            },
        ];

        if (editingItem.IsSerialized && !editingItem.UnknownItem) {
            columns.push({
                align: 'left',
                headerAlign: 'left',
                field: 'Serials',
                headerName: 'Serial',
                flex: 3,
                valueGetter: (value: any) => {
                    return !!value && value.length > 0
                        ? serialDisplay(value[0], !!editingItem.SerialVerify?.Serial2Alias)
                        : null;
                },

                renderCell: params => (
                    <Box sx={{ mt: ROW_TOP_MARGIN }}>
                        {!!params.value ? (
                            <Typography>{params.value}</Typography>
                        ) : (
                            <Typography color="error.main">MISSING</Typography>
                        )}
                    </Box>
                ),
            });

            columns.push({
                align: 'left',
                headerAlign: 'left',
                field: 'MissingSerialReason',
                headerName: 'Missing Serial Reason',
                flex: 5,
                valueGetter: (params: any, row) => {
                    return !!row.MissingSerialReason ? row.MissingSerialReason.Reason : '-';
                },
                renderCell: params => (
                    <Box sx={{ mt: ROW_TOP_MARGIN }}>
                        <Typography>{params.value}</Typography>
                    </Box>
                ),
            });

            columns.push({
                align: 'left',
                headerAlign: 'left',
                field: 'SerialNotes',
                headerName: 'Serial Notes',
                flex: 3,
                valueGetter: (value: any, row) => {
                    return !!row.SerialNotes ? row.SerialNotes : '-';
                },
                renderCell: params => (
                    <Box sx={{ mt: ROW_TOP_MARGIN }}>
                        <Typography>{params.value}</Typography>
                    </Box>
                ),
            });
        }

        if (!hasSubmitted(rma?.Status)) {
            columns.push({
                align: 'center',
                headerAlign: 'center',
                field: 'ValidToSubmit',
                headerName: 'Processed?',
                flex: 3,
                renderCell: params => (
                    <Box sx={{ mt: ROW_TOP_MARGIN }}>
                        {!!params.value ? (
                            <Typography color="success.main">YES</Typography>
                        ) : (
                            <Typography color="warning.main">NO</Typography>
                        )}
                    </Box>
                ),
            });
            columns.push({
                align: 'right',
                headerAlign: 'right',
                field: 'EditButton',
                headerName: '',
                flex: 3,
                sortable: false,
                valueGetter: (params: any, row) => {
                    return row.index;
                },
                renderCell: params => (
                    <Box sx={{ p: 1 }}>
                        <Button
                            onClick={() => {
                                setIndividualUnitDialog(true);
                                setCurrentUnitIndex(params.value);
                            }}
                            size="small"
                            variant={params.row.ValidToSubmit ? 'outlined' : 'contained'}
                        >
                            {params.row.ValidToSubmit ? 'EDIT' : 'PROCESS'}
                        </Button>
                    </Box>
                ),
            });
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                <ReceivedItemUnitDetailIndividualFormDialog
                    expectedItem={expectedItem}
                    receivedItem={editingItem}
                    setReceivedItem={setEditingItem}
                    unitIndex={currentUnitIndex}
                    handleOpenDialog={setIndividualUnitDialog}
                    showDialog={individualUnitDialog}
                />
                <DataGrid
                    hideFooter={gridUnitsData.length < 100}
                    rows={gridUnitsData || []}
                    columns={columns}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    disableRowSelectionOnClick
                    getRowId={(row: any) => row.datagridId}
                />
            </Box>
        );
    }
);
