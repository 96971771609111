import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import DialogCloseButton from '../../../../../common/components/Dialog/DialogCloseButton';
import { DISPOSITION_OTHER, NEW_RECEIVED_ITEM_UNIT_DETAIL } from '../../../constants';
import { ReceivedItem, ReceivedItemSerial, ReceivedItemUnitDetail } from '../../../rmaModels';
import { checkItemUnitReadyToSubmit } from '../../../utilities';
import { ReceivedItemUnitDetailBulkStatusAssignmentForm } from './ReceivedItemUnitDetailBulkStatusAssignmentForm';

export const ReceivedItemUnitDetailBulkStatusAssignmentDialog = React.memo(
    ({
        receivedItem,
        setReceivedItem,
        handleOpenDialog,
        showDialog = false,
        dialogTitle,
    }: {
        receivedItem: ReceivedItem;
        setReceivedItem: (item: ReceivedItem) => void;
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
        dialogTitle: string;
    }) => {
        const newReceivedItemUnitDetail = {
            ...NEW_RECEIVED_ITEM_UNIT_DETAIL,
            Disposition: receivedItem.UnknownItem ? DISPOSITION_OTHER : null,
        };

        const [bulkEditingItemUnit, setBulkEditingItemUnit] = React.useState<ReceivedItemUnitDetail>(
            newReceivedItemUnitDetail
        );
        const [scannedSerials, setScannedSerials] = React.useState<ReceivedItemSerial[]>([]);
        const [showErrors, setShowErrors] = React.useState(false);
        const unprocessedUnitCount = receivedItem.ItemUnitDetails.filter(unit => !unit.ValidToSubmit).length;

        const closeWithoutSaving = () => {
            handleOpenDialog(false);
            setBulkEditingItemUnit(newReceivedItemUnitDetail);
        };

        const resetVariablesToInitial = () => {
            setShowErrors(false);
            setScannedSerials([]);
        };

        const handleSaveEditedItem = (unprocessedOnly: boolean) => {
            //Check if fields are properly filled out
            if (!passFormErrorValidation()) {
                setShowErrors(true);
                return;
            }
            if (unprocessedOnly) {
                savedUnprocessedEditedItemUnits();
            } else {
                overrideProcessedUnits();
            }
            resetVariablesToInitial();
            handleOpenDialog(false);
        };

        const savedUnprocessedEditedItemUnits = () => {
            let serialIndex = 0; // Track serials separately from the unit index

            const updatedUnits = receivedItem.ItemUnitDetails.map(unit => {
                // Skip units that already have serials
                if (unit.ValidToSubmit) {
                    return unit;
                }

                let updatedUnit;

                if (serialIndex < scannedSerials.length) {
                    // Assign from serials if available and increment the serialIndex
                    updatedUnit = {
                        ...bulkEditingItemUnit,
                        Serials: [scannedSerials[serialIndex]],
                        ValidToSubmit: true,
                        MissingSerialReason: null,
                        SerialNotes: null,
                    };
                    serialIndex++;
                } else {
                    updatedUnit = { ...bulkEditingItemUnit, ValidToSubmit: true, Serials: unit.Serials };
                }

                return !unit.ValidToSubmit ? updatedUnit : unit;
            });

            setReceivedItem({
                ...receivedItem,
                ItemUnitDetails: updatedUnits,
                Serials: updatedUnits.map(unit => unit.Serials).flat(),
                ValidToSubmit: !updatedUnits.some(unit => !unit.ValidToSubmit),
            });
        };

        const overrideProcessedUnits = () => {
            const updatedUnits = receivedItem.ItemUnitDetails.map(unit => {
                const updatedUnit = {
                    ...unit,
                    Disposition: bulkEditingItemUnit.Disposition,
                    DispositionNotes: bulkEditingItemUnit.DispositionNotes,
                    ValidToSubmit: true,
                };

                return updatedUnit;
            });

            setReceivedItem({
                ...receivedItem,
                ItemUnitDetails: updatedUnits,
                Serials: updatedUnits.map(unit => unit.Serials).flat(),
                ValidToSubmit: !updatedUnits.some(unit => !unit.ValidToSubmit),
            });
        };

        const passFormErrorValidation = () => {
            return checkItemUnitReadyToSubmit(
                bulkEditingItemUnit,
                receivedItem.IsSerialized,
                scannedSerials.length >= unprocessedUnitCount,
                receivedItem.UnknownItem
            );
        };

        return (
            <React.Fragment>
                <Dialog open={showDialog} maxWidth="md" fullWidth>
                    <DialogTitle color="white" bgcolor="primary.main" align="center">
                        <Typography fontWeight={'fontWeightMedium'} variant="h5">
                            {dialogTitle}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <ReceivedItemUnitDetailBulkStatusAssignmentForm
                                editingItem={receivedItem}
                                setEditingItem={setReceivedItem}
                                editingItemUnit={bulkEditingItemUnit}
                                setEditingItemUnit={setBulkEditingItemUnit}
                                serials={scannedSerials}
                                setSerials={setScannedSerials}
                                showErrors={showErrors}
                            />
                            <Box sx={{ display: 'flex', my: 1, mx: 4, justifyContent: 'space-between' }}>
                                {!!unprocessedUnitCount ? (
                                    <Button
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={() => handleSaveEditedItem(true)}
                                        variant="contained"
                                        sx={{ flex: 5 }}
                                    >
                                        APPLY TO {unprocessedUnitCount} UNPROCESSED UNITS
                                    </Button>
                                ) : (
                                    <Button
                                        color="error"
                                        size="large"
                                        fullWidth
                                        onClick={() => handleSaveEditedItem(false)}
                                        variant="contained"
                                        sx={{ flex: 2 }}
                                    >
                                        OVERRIDE CHANGES TO ALL UNITS
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogCloseButton handleClose={closeWithoutSaving} />
                </Dialog>
            </React.Fragment>
        );
    }
);
