import { Button, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useAppSelector } from '../../../../app/store';

export const AddFromOriginalOrderDisplay = React.memo(
    ({
        setSku,
        submitStockQuery,
    }: {
        setSku: (sku: string | null | undefined) => void;
        submitStockQuery: (sku: string) => void;
    }) => {
        const rma = useAppSelector(state => state.rma.rma);
        const columns: GridColDef[] = [
            {
                field: 'Description',
                headerName: 'Description',
                flex: 3,
                renderCell: (params: any) => {
                    return <Typography sx={{ mt: 0.5 }}>{params.value}</Typography>;
                },
            },
            {
                field: 'Item',
                headerName: 'SKU',
                flex: 2,
                renderCell: (params: any) => {
                    return <Typography sx={{ mt: 0.5 }}>{params.value}</Typography>;
                },
            },
            {
                align: 'center',
                headerAlign: 'center',
                field: 'QuantityShip',
                headerName: 'Qty',
                type: 'number',
                flex: 1.5,
                renderCell: (params: any) => {
                    return <Typography sx={{ mt: 0.5 }}>{params.value}</Typography>;
                },
            },
            {
                align: 'center',
                headerAlign: 'center',
                field: 'Id',
                headerName: '',
                flex: 1.5,
                renderCell: (params: any) => {
                    return (
                        <Button
                            size="small"
                            onClick={() => {
                                setSku(params.row.Item);
                                submitStockQuery(params.row.Item);
                            }}
                        >
                            SELECT
                        </Button>
                    );
                },
            },
        ];

        if (!rma?.OriginalOrderDetails || rma?.OriginalOrderDetails.Items.length <= 0) {
            return <React.Fragment />;
        }

        return (
            <Paper sx={{ mb: 4, p: 2 }} elevation={4}>
                <Typography>Items Shipped From Original Outbound Order</Typography>
                <DataGrid
                    getRowId={row => row.ItemId}
                    rows={rma?.OriginalOrderDetails.Items || []}
                    columns={columns}
                    disableRowSelectionOnClick
                    scrollbarSize={20}
                    hideFooter
                    autoHeight
                    getRowHeight={() => 'auto'}
                />
            </Paper>
        );
    }
);
