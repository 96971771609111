export const PALLET_ACTIONS = 'PalletActions';
export const PALLET_TAG_VIEW = 'PalletTagView';
export const ROLE_CREATE = 'RoleCreate';
export const ROLE_DELETE = 'RoleDelete';
export const ROLE_EDIT = 'RoleEdit';
export const ROLE_PERMISSION_EDIT = 'RolePermissionEdit';
export const ROLE_PERMISSION_VIEW = 'RolePermissionView';
export const ROLE_VIEW = 'RoleView';
export const TAG_ACTION_CLONE = 'TagActionClone';
export const TAG_ACTION_LOCATION_CHANGE = 'TagActionLocationChange';
export const TAG_ACTION_QUARANTINE = 'TagActionQuarantine';
export const TAG_ACTION_RETIRE = 'TagActionRetire';
export const TAG_ACTION_VALIDATE = 'TagActionValidate';
export const TASK_APPROVE = 'TaskApprove';
export const TASK_COUNT = 'TaskCount';
export const TASK_CREATE = 'TaskCreate';
export const TASK_DELETE = 'TaskDelete';
export const TASK_DISCARD = 'TaskDiscard';
export const TASK_REASSIGN = 'TaskReassign';
export const TASK_REJECT = 'TaskReject';
export const TASK_UPDATE = 'TaskUpdate';
export const TASK_VIEW = 'TaskView';
export const QUALITY_CONTROL_USER_ACTIONS = 'QualityControlUserActions';
export const SERIALIZED_BATCH_SHIPPING_ACCESS = 'SerializedBatchShippingAccess';
export const CLEAR_SERIAL = 'SerialClear';
export const ACCESS_WORK_ORDER = 'AccessWorkOrder';
export const RECEIVER_ACCESS = 'ReceiverAccess';
export const INBOUND_ACCESS = 'InboundAccess';
export const SERIAL_LOCATION_ACCESS = 'SerialLocationAccess';
export const INACTIVE_COMPANY_VIEW = 'InactiveCompanyView';
export const RESET_QC = 'ResetQc';
export const RESET_MANIFEST = 'ResetManifest';
export const PROCEDURE_ACCESS = 'ProcedureAccess';
export const ORDER_LOOKUP_ACCESS = 'OrderLookupAccess';
export const RMA_ACCESS = 'RmaAccess';
export const ACCESS_SKU_VELOCITY = 'AccessSkuVelocity';
export const BATCH_ORDER_MONITOR_ACCESS = 'BatchOrderMonitorAccess';
export const RELABEL_ACCESS = 'RelabelAccess';
export const PRINTER_ACCESS = 'PrinterAccess';
