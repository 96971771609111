import { Paper } from '@mui/material';
import printerApi from '../printerApi';
import AssignmentDatagrid from './AssignmentDatagrid';
import PrintFormV2 from './PrintFormV2';

const PrinterElement = () => {
    const getAssignmentResponse = printerApi.endpoints.getAssignments.useQuery();

    return (
        <>
            <PrintFormV2 />

            <Paper sx={{ mt: 2, p: 2 }}>
                <AssignmentDatagrid
                    assignments={getAssignmentResponse.data || []}
                    key={JSON.stringify(getAssignmentResponse.data)}
                />
            </Paper>
        </>
    );
};

export default PrinterElement;
