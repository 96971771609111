import { GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import * as React from 'react';
import { useAppDispatch } from '../../../app/store';
import EdittableDatagrid, {
    EDITTABLE_DATAGRID_NEW_ROW_ID,
} from '../../../common/components/datagrid/EdittableDatagrid';
import useMutationResponse from '../../../common/hooks/useMutationResponse';
import { setDialog } from '../../notification/notificationSlice';
import { PrinterRecord, UpdatePrinterRequest } from '../models';
import printerApi from '../printerApi';
import { EditPrinterName } from './EditPrinterName';

const NO_COMPUTER_OPTION = { Name: 'NO COMPUTER' };

export default function PrinterDatagrid({
    printers,
    onRowClick = () => {},
}: {
    printers: PrinterRecord[];
    onRowClick?: (id: number) => void;
}) {
    const [rows, setRows] = React.useState(printers);
    const dispatch = useAppDispatch();

    const getComputersResponse = printerApi.endpoints.getComputers.useQuery();
    const getConnectionTypeResponse = printerApi.endpoints.getConnectionType.useQuery();
    const getOutputTypeResponse = printerApi.endpoints.getOutputType.useQuery();

    const initialRow: UpdatePrinterRequest = {
        Id: EDITTABLE_DATAGRID_NEW_ROW_ID,
        Name: '',
        ComputerId: getComputersResponse?.data?.[0].Id || -1,
        ConnectionTypeId: getConnectionTypeResponse?.data?.[0].Id || -1,
        OutputTypeId: getOutputTypeResponse?.data?.[0].Id || -1,
    };

    const [triggerCreate, createResponse] = printerApi.endpoints.createPrinter.useMutation();
    const [triggerUpdate, updateResponse] = printerApi.endpoints.updatePrinter.useMutation();
    const [triggerDelete, deleteResponse] = printerApi.endpoints.deletePrinter.useMutation();

    useMutationResponse({ response: createResponse, errorMessage: `Failed to create printer` });
    useMutationResponse({ response: updateResponse, errorMessage: `Failed to update printer` });
    useMutationResponse({ response: deleteResponse, errorMessage: `Failed to delete printer` });

    const handleDeleteClick = (id: GridRowId) => {
        const matchingRow = rows.find(row => row.Id === id);
        if (!matchingRow) return;

        triggerDelete(matchingRow.Id);
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const matchingRow = { ...newRow } as PrinterRecord;

        if (!matchingRow) return matchingRow;

        if (!matchingRow.Name) {
            dispatch(setDialog({ content: 'Name field is required' }));
            return matchingRow;
        }

        setRows(rows.map(row => (row.Id === newRow.Id ? matchingRow : row)));

        if (newRow.Id === EDITTABLE_DATAGRID_NEW_ROW_ID) triggerCreate(matchingRow);
        else triggerUpdate(matchingRow);

        return matchingRow;
    };

    const getAccessiblePrintersResponse = printerApi.endpoints.getAccessiblePrinters.useQuery();

    const columns: GridColDef<PrinterRecord>[] = [
        {
            field: 'Id',
            headerName: 'Id',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (value: any) => (value === EDITTABLE_DATAGRID_NEW_ROW_ID ? '-' : value),
        },
        {
            field: 'Name',
            headerName: 'Printer Name',
            flex: 3,
            editable: true,
            type: 'singleSelect',
            valueOptions: getAccessiblePrintersResponse.data,
            renderEditCell: EditPrinterName,
        },
        {
            field: 'ComputerId',
            headerName: 'Computer',
            flex: 2,
            editable: true,
            type: 'singleSelect',
            valueOptions: getComputersResponse.data
                ? [...getComputersResponse.data, NO_COMPUTER_OPTION]
                : [NO_COMPUTER_OPTION],
            getOptionValue: (value: any) => value?.Id,
            getOptionLabel: (value: any) => value?.Name,
        },
        {
            field: 'ConnectionTypeId',
            headerName: 'Connection Type',
            flex: 1,
            editable: true,
            type: 'singleSelect',
            valueOptions: getConnectionTypeResponse.data || [],
            getOptionValue: (value: any) => value?.Id,
            getOptionLabel: (value: any) => value?.Name,
        },
        {
            field: 'OutputTypeId',
            headerName: 'Output Type',
            flex: 1,
            editable: true,
            type: 'singleSelect',
            valueOptions: getOutputTypeResponse.data || [],
            getOptionValue: (value: any) => value?.Id,
            getOptionLabel: (value: any) => value?.Name,
        },
    ];

    return (
        <EdittableDatagrid
            rows={rows}
            setRows={setRows}
            initialRow={initialRow}
            getRowId={row => row.Id}
            columns={columns}
            handleDeleteClick={handleDeleteClick}
            processRowUpdate={processRowUpdate}
            onRowClick={(params, event) => onRowClick(+params.id)}
        />
    );
}
