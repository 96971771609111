import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationResponse, OperatingSystemProperties, SetAuthenticationPayload } from './authenticationModels';

export interface AuthenticationState {
    authenticationResponse?: AuthenticationResponse;
    routeLoggedOutFrom: string;
    username: string;
    fullName: string;
    systemProperties?: OperatingSystemProperties;
}

const initialState: AuthenticationState = {
    authenticationResponse: undefined,
    routeLoggedOutFrom: '',
    username: '',
    fullName: '',
};

const slice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAuthentication: (state: AuthenticationState, action: PayloadAction<SetAuthenticationPayload>) => {
            state.authenticationResponse = action.payload.response;
            if (action.payload.username) state.username = action.payload.username;
            if (action.payload.response.userInfo)
                state.fullName = `${action.payload.response.userInfo.firstName} ${action.payload.response.userInfo.lastName}`;
        },
        logOut: (state, action: PayloadAction<void>) => {
            state.authenticationResponse = undefined;
        },
        setRouteLoggedOutFrom: (state, action: PayloadAction<string>) => {
            state.routeLoggedOutFrom = action.payload;
        },
        setSystemProperties: (state, action: PayloadAction<OperatingSystemProperties>) => {
            state.systemProperties = action.payload;
        },
    },
});

export const { setAuthentication, logOut, setRouteLoggedOutFrom, setSystemProperties } = slice.actions;

export default slice.reducer;

// export const selectGlobalPollingEnabled = (state: RootState) => state.polling.enabled;
// export const selectPollingConfigByApp = (state: RootState, app: PollingAppKey) => state.polling.apps[app];
