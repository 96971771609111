import {
    DELETE_SEAL_DELETE_SERIAL_URL,
    GET_QC_ORDER_STATUS_URL_POSTFIX,
    GET_QC_ORDER_STATUS_URL_PREFIX,
    GET_SEAL_HISTORY_ORDER_URL,
    GET_SEAL_ORDER_LOOKUP_URL,
    POST_BUILD_COMMERICAL_INVOICE_PDF_URL,
    POST_SEAL_ADD_SERIALS_URL,
    PUT_SEAL_UPDATE_SERIAL_URL,
    RESET_MANIFEST_URL_POSTFIX,
    RESET_QC_URL_POSTFIX,
} from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import { STATUS_VALUE } from '../qualityControl/qualityControlModels';
import {
    EditSerialsResponse,
    GetHistoryOrderResponse,
    GetOrderDetailsResponse,
    Item,
    Serial,
} from './orderLookupModels';

const createQCStatusUpdateBody: any = (userId: number) => {
    return {
        userId: userId,
        system: 'n/a',
        qcVersion: 'n/a',
        force: true,
    };
};

export const orderLookupApi = baseApi.enhanceEndpoints({
    addTagTypes: ['OrderDetailsByMomCode', 'InvoiceQcStatus'],
});

export default orderLookupApi.injectEndpoints({
    endpoints: builder => ({
        getOrderDetails: builder.query<GetOrderDetailsResponse, { momCode: string; orderId: number }>({
            query: param => ({
                url: prependDreamUrl(`${GET_SEAL_ORDER_LOOKUP_URL}/${param.orderId}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: `${param.momCode}`,
                },
            }),
            providesTags: ['OrderDetailsByMomCode'],
        }),
        getOrderDetailsByTracking: builder.query<GetOrderDetailsResponse, { momCode: string; trackingNumber: string }>({
            query: param => ({
                url: prependDreamUrl(GET_SEAL_ORDER_LOOKUP_URL),
                method: 'GET',
                params: param,
                headers: {
                    RushOrderMomCode: `${param.momCode}`,
                },
            }),
            providesTags: ['OrderDetailsByMomCode'],
        }),
        getOrderQcStatus: builder.query<STATUS_VALUE, string>({
            query: invoice => ({
                url: prependDreamUrl(`${GET_QC_ORDER_STATUS_URL_PREFIX}${invoice}${GET_QC_ORDER_STATUS_URL_POSTFIX}`),
                method: 'GET',
            }),
            providesTags: ['InvoiceQcStatus'],
        }),
        getHistoryOrder: builder.query<GetHistoryOrderResponse, { momCode: string; orderId: number }>({
            query: param => ({
                url: prependDreamUrl(`${GET_SEAL_HISTORY_ORDER_URL}/${param.orderId}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: `${param.momCode}`,
                },
            }),
            providesTags: ['OrderDetailsByMomCode'],
        }),
        putUpdateSerial: builder.mutation<
            EditSerialsResponse,
            Partial<{ serials: Serial; momCode: string; itemId: number; allowDuplicates: boolean }>
        >({
            query: param => ({
                url: prependDreamUrl(PUT_SEAL_UPDATE_SERIAL_URL),
                method: 'PUT',
                body: {
                    SerialRecordsData: param.serials,
                    AllowSerialDuplicates: param.allowDuplicates,
                    MomCode: `${param.momCode}`,
                },
            }),
            invalidatesTags: ['OrderDetailsByMomCode'],
        }),
        deleteSerial: builder.mutation<
            EditSerialsResponse,
            Partial<{ serials: Serial; momCode: string; itemId: number }>
        >({
            query: param => ({
                url: prependDreamUrl(DELETE_SEAL_DELETE_SERIAL_URL),
                method: 'DELETE',
                body: {
                    SerialRecordsData: param.serials,
                    MomCode: `${param.momCode}`,
                },
            }),
            invalidatesTags: ['OrderDetailsByMomCode'],
        }),
        postAddSerials: builder.mutation<
            EditSerialsResponse,
            Partial<{ serials: Serial[]; orderId: number; momCode: string; item: Item }>
        >({
            query: param => ({
                url: prependDreamUrl(POST_SEAL_ADD_SERIALS_URL),
                method: 'POST',
                body: {
                    SerialRecordsData: param.serials,
                    ItemId: param.item?.ItemId,
                    Item: param.item?.Item,
                    Inpart: param.item?.Inpart,
                    OrderId: param.orderId,
                    AllowSerialDups: param.item?.AllowSerialDups,
                    MomCode: `${param.momCode}`,
                },
            }),
            invalidatesTags: ['OrderDetailsByMomCode'],
        }),
        putResetQc: builder.mutation<void, Partial<{ invoice: string; userId: number }>>({
            query: param => ({
                url: prependDreamUrl(`${GET_QC_ORDER_STATUS_URL_PREFIX}${param.invoice}${RESET_QC_URL_POSTFIX}`),
                method: 'PUT',
                body: createQCStatusUpdateBody(param.userId),
            }),
            invalidatesTags: ['OrderDetailsByMomCode', 'InvoiceQcStatus'],
        }),
        putResetManifest: builder.mutation<void, Partial<{ invoice: string; userId: number; momCode: string }>>({
            query: param => ({
                url: prependDreamUrl(`${GET_QC_ORDER_STATUS_URL_PREFIX}${param.invoice}${RESET_MANIFEST_URL_POSTFIX}`),
                method: 'PUT',
                body: createQCStatusUpdateBody(param.userId),
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
            invalidatesTags: ['OrderDetailsByMomCode', 'InvoiceQcStatus'],
        }),
        postBuildCommercialInvoicePdf: builder.query<any, { momCode: string; orderId: number; inpart: string }>({
            query: param => ({
                url: prependDreamUrl(
                    `${POST_BUILD_COMMERICAL_INVOICE_PDF_URL}?orderId=${param.orderId}&inpart=${param.inpart}`
                ),
                method: 'POST',
                headers: {
                    RushOrderMomCode: `${param.momCode}`,
                },
                responseHandler: response => response.json(),
            }),
        }),
    }),
});
