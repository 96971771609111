import { Box, Button, Card, CardContent, Checkbox, Chip, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { PairedRmaItem } from '../../rmaModels';
import { ItemCardMedia } from '../Media/ItemCardMedia';
import { ReceivedItemUnitDetailDialog } from '../ReceivedItems/ReceivedItemUnitDetailDialog';
import { ScannedSerialsDisplay } from '../Serials/ScannedSerialsDisplay';
import { useAppSelector } from '../../../../app/store';
import { getDetailsButtonLabel, hasSubmitted } from '../../utilities';

export const ItemCard = ({
    pairedRmaItems,
    setPairedRmaItems,
    index,
}: {
    pairedRmaItems: PairedRmaItem[];
    setPairedRmaItems: (addingPair: PairedRmaItem[] | ((prevPair: PairedRmaItem[]) => PairedRmaItem[])) => void;
    index: number;
}) => {
    const rma = useAppSelector(state => state.rma.rma);
    const [receivedItem, setReceivedItem] = React.useState(pairedRmaItems[index].ReceivedItem);
    const [recievedItemDetailDialog, setRecievedItemDetailDialog] = React.useState(false);
    const expectedItem = pairedRmaItems[index].ExpectedItem;
    const missingOpacity = receivedItem.NotFound ? 0.3 : 1;

    useEffect(() => {
        const isMissing = receivedItem.NotFound || receivedItem.Quantity === 0;

        if (isMissing) {
            setReceivedItem({
                ...receivedItem,
                Serials: [],
                Quantity: 0,
                NotFound: isMissing,
                ItemUnitDetails: [],
            });
        }
    }, [receivedItem.NotFound, receivedItem.Quantity]);

    useEffect(() => {
        const updatedItems = pairedRmaItems.map((pair, currentIndex) =>
            currentIndex === index ? { ...pair, ReceivedItem: receivedItem } : pair
        );

        setPairedRmaItems(updatedItems);
    }, [receivedItem]);

    const receivedQtyTextColor = () => {
        const expectedQtyCleaned = !!expectedItem.Quantity ? expectedItem.Quantity : 0;
        if (receivedItem.Quantity == null || receivedItem.Quantity > expectedQtyCleaned) {
            return 'warning.main';
        }

        if (receivedItem.Quantity < expectedQtyCleaned) {
            return 'error.main';
        }
        return 'success.main';
    };

    const card = (
        <React.Fragment>
            <CardContent>
                <Box sx={{ px: 2, pt: 1 }}>
                    <Typography color="primary.main" variant="h5" sx={{ opacity: missingOpacity }}>
                        {expectedItem.Description}
                    </Typography>
                    <Typography color="primary.main" gutterBottom sx={{ opacity: missingOpacity }}>
                        SKU: {expectedItem.Sku}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                        }}
                    >
                        <Box sx={{ flex: 2, px: 1, pb: 3, pt: 1, opacity: missingOpacity }}>
                            <ItemCardMedia sku={expectedItem.Sku} />
                        </Box>
                        <Box
                            sx={{
                                flex: 1.5,
                                px: 3,
                                pb: 3,
                                pt: 1.5,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {!hasSubmitted(rma?.Status) && (
                                    <Box sx={{ mb: 1.5, ml: -0.5 }}>
                                        <Chip
                                            label={receivedItem.ValidToSubmit ? 'PROCESSED' : 'UNPROCESSED'}
                                            color={receivedItem.ValidToSubmit ? 'success' : 'warning'}
                                        />
                                    </Box>
                                )}
                                <Typography fontWeight={'fontWeightMedium'} gutterBottom>
                                    EXPECTED QTY: {expectedItem.Quantity}
                                </Typography>
                                <Typography
                                    sx={{ mt: -0.5 }}
                                    fontWeight={'fontWeightMedium'}
                                    color={receivedQtyTextColor()}
                                >
                                    {receivedItem.Quantity != null ? `RECEIVED QTY: ${receivedItem.Quantity}` : ''}
                                </Typography>
                                <ScannedSerialsDisplay
                                    serials={receivedItem.Serials}
                                    receivedItem={receivedItem}
                                    isSerialized={expectedItem.IsSerialized}
                                    missingSerialCount={
                                        (expectedItem.IsSerialized && !!receivedItem.Quantity
                                            ? receivedItem.Quantity
                                            : 0) - receivedItem.Serials.length
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {hasSubmitted(rma?.Status) ? (
                            <Typography color="warning.main">
                                {receivedItem.NotFound ? 'ITEM NOT FOUND' : ''}
                            </Typography>
                        ) : (
                            <FormControlLabel
                                label="ITEM NOT FOUND"
                                control={
                                    <Checkbox
                                        checked={receivedItem.NotFound}
                                        onChange={event =>
                                            setReceivedItem({
                                                ...receivedItem,
                                                NotFound: event.target.checked,
                                                Quantity: null,
                                                ValidToSubmit: event.target.checked,
                                            })
                                        }
                                    />
                                }
                            />
                        )}

                        <Button
                            variant={receivedItem.ValidToSubmit ? 'outlined' : 'contained'}
                            onClick={() => {
                                setRecievedItemDetailDialog(true);
                            }}
                            disabled={receivedItem.NotFound}
                        >
                            {getDetailsButtonLabel(hasSubmitted(rma?.Status), receivedItem.ValidToSubmit)}
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </React.Fragment>
    );
    return (
        <React.Fragment>
            <ReceivedItemUnitDetailDialog
                handleOpenDialog={setRecievedItemDetailDialog}
                showDialog={recievedItemDetailDialog}
                receivedItem={receivedItem}
                setReceivedItem={setReceivedItem}
                expectedItem={expectedItem}
            />
            <Card>{card}</Card>
        </React.Fragment>
    );
};
