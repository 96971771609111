import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../app/store';

export const RmaDetailsDisplay = () => {
    const rma = useAppSelector(state => state.rma.rma);
    const has_details = !!rma?.Notes;
    if (!has_details) return <React.Fragment />;
    return (
        <Box sx={{ mb: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography fontWeight={'fontWeightMedium'} variant="h5" color="primary.main" gutterBottom>
                    RMA DETAILS
                </Typography>
            </Box>
            <Card>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'left', px: 2 }}>
                        <Box>
                            <Typography color="primary.main" sx={{ flex: 1 }}>
                                NOTES
                            </Typography>
                            <Typography variant="body2" sx={{ flex: 1 }}>
                                {rma?.Notes}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};
