import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { BULK_UNIT_THRESHOLD_QTY, DISPOSITION_OTHER, NEW_RECEIVED_ITEM_UNIT_DETAIL } from '../../constants';
import { ExpectedItem, ReceivedItem, ReceivedItemUnitDetail } from '../../rmaModels';
import { ReceivedItemUnitDetailBulkForm } from './BulkItemUnitDetail/ReceivedItemUnitDetailBulkForm';
import { ReceivedItemUnitDetailBulkStatusAssignmentDialog } from './BulkItemUnitDetail/ReceivedItemUnitDetailBulkStatusAssignmentDialog';
import { ReceivedItemUnitDetailForm } from './ReceivedItemUnitDetailForm';
import { useAppSelector } from '../../../../app/store';
import { hasSubmitted } from '../../utilities';

export const ReceivedItemUnitDetailDisplay = React.memo(
    ({
        expectedItem,
        editingItem,
        setEditingItem,
        showErrors = false,
    }: {
        expectedItem?: ExpectedItem;
        editingItem: ReceivedItem;
        setEditingItem: (item: ReceivedItem | ((prevItem: ReceivedItem) => ReceivedItem)) => void;
        showErrors?: boolean;
    }) => {
        const newReceivedItemUnitDetail = {
            ...NEW_RECEIVED_ITEM_UNIT_DETAIL,
            Disposition: editingItem.UnknownItem ? DISPOSITION_OTHER : null,
        };

        const rma = useAppSelector(state => state.rma.rma);
        const [bulkStatusAssignmentDialog, setBulkStatusAssignmentDialog] = React.useState(false);
        const [receivedQtyFieldValue, setReceivedQtyFieldValue] = React.useState(editingItem.Quantity);
        const receivedItemNotStarted = editingItem.Quantity === null || editingItem.Quantity === undefined;
        const noUnprocessedUnits = !editingItem.ItemUnitDetails.some((unit, index) => !unit.ValidToSubmit);
        const useBulkUnitsViewMode = !!editingItem.Quantity && editingItem.Quantity > BULK_UNIT_THRESHOLD_QTY;
        const hideAddUnitButton =
            hasSubmitted(rma?.Status) || editingItem.Quantity == null || editingItem.Quantity == undefined;
        const showNotSerializedSkuMsg =
            !editingItem.IsSerialized && !receivedItemNotStarted && editingItem.Serials.length <= 0;

        const bulkEditButtonTitle = noUnprocessedUnits
            ? 'OVERRIDE DISPOSITIONS FOR ALL UNITS'
            : 'ASSIGN FIELDS TO ALL UNPROCESSED UNITS';

        const overrideTooltipMsg = 'Override the same disposition and notes to all units.';
        const unprocessedBulkStatusTooltip = editingItem.IsSerialized
            ? 'Assign the same disposition, notes, and missing serial reasons to all unprocessed units.'
            : 'Assign the same disposition and notes to all unprocessed units.';

        const receivedQtyColor = () => {
            const expectedQty = !!expectedItem?.Quantity ? expectedItem?.Quantity : 0;
            const editingQty = !!editingItem?.Quantity ? editingItem?.Quantity : 0;
            if (!!!expectedQty) {
                return 'primary.main';
            }

            if (expectedQty < editingQty) {
                return 'warning.main';
            }
            if (expectedQty > editingQty) {
                return 'error.main';
            }
            return 'success.main';
        };

        const bulkStatusTooltip = () => {
            if (noUnprocessedUnits) {
                return overrideTooltipMsg;
            }
            return unprocessedBulkStatusTooltip;
        };

        const changeItemUnitDetails = () => {
            const currentUnitLength = !!editingItem.ItemUnitDetails ? editingItem.ItemUnitDetails.length : 0;
            const currentReceivedQty = !!editingItem.Quantity ? editingItem.Quantity : 0;
            const difference = currentReceivedQty - currentUnitLength;
            if (difference > 0) {
                const updatedUnits: ReceivedItemUnitDetail[] = editingItem.ItemUnitDetails;
                for (let i = 0; i < difference; i++) {
                    updatedUnits.push(newReceivedItemUnitDetail);
                }
                setEditingItem({ ...editingItem, ItemUnitDetails: updatedUnits });
            } else if (difference < 0) {
                const updatedUnits = editingItem.ItemUnitDetails.slice(0, currentReceivedQty);
                setEditingItem({ ...editingItem, ItemUnitDetails: updatedUnits });
            }
        };

        const handleQtyValueChange = (value: string) => {
            const newValue = parseInt(value, 10);
            const cleanedValue = isNaN(newValue) ? 0 : newValue;
            const minValue = !!expectedItem ? 0 : 1;
            return minValue >= cleanedValue ? minValue : cleanedValue;
        };

        React.useEffect(() => {
            changeItemUnitDetails();
            if (receivedQtyFieldValue != editingItem.Quantity) {
                setReceivedQtyFieldValue(editingItem.Quantity);
            }
        }, [editingItem.Quantity]);

        const handleAddUnit = (prepend: boolean) => {
            if (prepend) {
                setEditingItem({
                    ...editingItem,
                    Quantity: (!!editingItem.Quantity ? editingItem.Quantity : 0) + 1,
                    ItemUnitDetails: [newReceivedItemUnitDetail, ...editingItem.ItemUnitDetails],
                });
            } else {
                setEditingItem({
                    ...editingItem,
                    Quantity: (!!editingItem.Quantity ? editingItem.Quantity : 0) + 1,
                    ItemUnitDetails: [...editingItem.ItemUnitDetails, newReceivedItemUnitDetail],
                });
            }
        };

        return (
            <React.Fragment>
                <ReceivedItemUnitDetailBulkStatusAssignmentDialog
                    receivedItem={editingItem}
                    setReceivedItem={setEditingItem}
                    handleOpenDialog={setBulkStatusAssignmentDialog}
                    showDialog={bulkStatusAssignmentDialog}
                    dialogTitle={bulkEditButtonTitle}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-end' }}>
                        {!!expectedItem && <Typography variant="h6">EXPECTED QTY: {expectedItem.Quantity}</Typography>}
                        {receivedItemNotStarted ? (
                            <React.Fragment>
                                <TextField
                                    sx={{ maxWidth: '250px' }}
                                    fullWidth
                                    value={receivedQtyFieldValue}
                                    type="number"
                                    id="recieveQty"
                                    label="RECEIVED QTY"
                                    autoFocus
                                    variant="standard"
                                    onChange={event => {
                                        setReceivedQtyFieldValue(handleQtyValueChange(event.target.value));
                                    }}
                                />
                                <Button
                                    disabled={receivedQtyFieldValue === null || receivedQtyFieldValue === undefined}
                                    onClick={() => {
                                        setEditingItem({ ...editingItem, Quantity: receivedQtyFieldValue });
                                    }}
                                    size="large"
                                    variant="contained"
                                >
                                    CONTINUE
                                </Button>
                            </React.Fragment>
                        ) : (
                            <Typography color={receivedQtyColor()} variant="h6">
                                RECEIVED QTY: {editingItem.Quantity}
                            </Typography>
                        )}

                        {showNotSerializedSkuMsg && (
                            <Typography variant="button" color="warning.main">
                                This SKU is not serialized
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: useBulkUnitsViewMode ? 'space-between' : 'flex-end',
                            mb: 1,
                        }}
                    >
                        {useBulkUnitsViewMode && !hideAddUnitButton && (
                            <Button variant="contained" sx={{ mt: 6 }} size="small" onClick={() => handleAddUnit(true)}>
                                ADD A UNIT
                            </Button>
                        )}
                        {!!editingItem.Quantity && editingItem.Quantity > 1 && !hasSubmitted(rma?.Status) && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 6 }}>
                                <Button
                                    onClick={() => {
                                        setBulkStatusAssignmentDialog(true);
                                    }}
                                    color={noUnprocessedUnits ? 'error' : 'primary'}
                                    size="small"
                                    variant="contained"
                                    sx={{ mr: 0.5 }}
                                >
                                    {bulkEditButtonTitle}
                                </Button>
                                <Tooltip title={bulkStatusTooltip()} placement="top-start">
                                    <InfoOutlinedIcon color="primary" />
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                    {useBulkUnitsViewMode ? (
                        <ReceivedItemUnitDetailBulkForm
                            expectedItem={expectedItem}
                            editingItem={editingItem}
                            setEditingItem={setEditingItem}
                        />
                    ) : (
                        <React.Fragment>
                            <ReceivedItemUnitDetailForm
                                editingItem={editingItem}
                                setEditingItem={setEditingItem}
                                showErrors={showErrors}
                            />
                        </React.Fragment>
                    )}
                    {!hideAddUnitButton && (
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 4 }} />
                            <Button
                                onClick={() => handleAddUnit(false)}
                                sx={{ flex: 1, mb: 1 }}
                                variant="contained"
                                size="large"
                            >
                                ADD A UNIT
                            </Button>
                        </Box>
                    )}
                </Box>
            </React.Fragment>
        );
    }
);
