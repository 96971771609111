import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl, prependRoAgentUrl } from '../../app/services/util';
import {
    AssignmentPrinterNameRequest,
    AssignmentPrinterNameResponse,
    AssignmentRecord,
    ComputerRecord,
    ConnectionTypeRecord,
    CreateAssignmentRequest,
    CreateComputerRequest,
    CreatePaperSizeRequest,
    CreatePrinterRequest,
    GetPrintPropertiesRequest,
    GetPrintPropertiesResponse,
    OutputTypeRecord,
    PaperSizeRecord,
    PrinterRecord,
    PrintRequest,
    UpdateAssignmentRequest,
    UpdateComputerRequest,
    UpdatePaperSizeRequest,
    UpdatePrinterRequest,
} from './models';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['Computer', 'Printer', 'PaperSize', 'Assignment'] });

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        print: builder.mutation<void, PrintRequest>({
            query: body => ({
                url: prependRoAgentUrl('api/printer/job'),
                method: 'POST',
                body: body,
            }),
        }),
        getLocalPaperSizes: builder.query<GetPrintPropertiesResponse[], GetPrintPropertiesRequest>({
            query: request => ({
                url: prependRoAgentUrl('api/printer/properties'),
                method: 'GET',
                params: request,
            }),
        }),
        getAccessiblePrinters: builder.query<string[], void>({
            query: () => ({
                url: prependRoAgentUrl('api/printers'),
                method: 'GET',
            }),
        }),

        createComputer: builder.mutation<void, CreateComputerRequest>({
            query: body => ({
                url: prependDreamUrl('api/roagent/printer/computer'),
                body,
                method: 'POST',
            }),
            invalidatesTags: ['Computer'],
        }),
        getComputers: builder.query<ComputerRecord[], void>({
            query: () => ({
                url: prependDreamUrl('api/roagent/printer/computer'),
                method: 'GET',
            }),
            providesTags: ['Computer'],
        }),
        updateComputer: builder.mutation<void, UpdateComputerRequest>({
            query: request => ({
                url: prependDreamUrl(`api/roagent/printer/computer/${request.Id}`),
                body: { ...request },
                method: 'PUT',
            }),
            invalidatesTags: ['Computer'],
        }),
        deleteComputer: builder.mutation<void, number>({
            query: id => ({
                url: prependDreamUrl(`api/roagent/printer/computer/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: ['Computer'],
        }),

        createPrinter: builder.mutation<void, CreatePrinterRequest>({
            query: body => ({
                url: prependDreamUrl('api/roagent/printer/printer'),
                body,
                method: 'POST',
            }),
            invalidatesTags: ['Printer'],
        }),
        getPrinters: builder.query<PrinterRecord[], void>({
            query: () => ({
                url: prependDreamUrl('api/roagent/printer/printer'),
                method: 'GET',
            }),
            providesTags: ['Printer'],
        }),
        updatePrinter: builder.mutation<void, UpdatePrinterRequest>({
            query: request => ({
                url: prependDreamUrl(`api/roagent/printer/printer/${request.Id}`),
                body: { ...request },
                method: 'PUT',
            }),
            invalidatesTags: ['Printer'],
        }),
        deletePrinter: builder.mutation<void, number>({
            query: id => ({
                url: prependDreamUrl(`api/roagent/printer/printer/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: ['Printer'],
        }),

        getConnectionType: builder.query<ConnectionTypeRecord[], void>({
            query: () => ({
                url: prependDreamUrl('api/roagent/printer/connectionType'),
                method: 'GET',
            }),
        }),

        getOutputType: builder.query<OutputTypeRecord[], void>({
            query: () => ({
                url: prependDreamUrl('api/roagent/printer/outputType'),
                method: 'GET',
            }),
        }),

        createPaperSize: builder.mutation<void, CreatePaperSizeRequest>({
            query: body => ({
                url: prependDreamUrl('api/roagent/printer/PaperSize'),
                body,
                method: 'POST',
            }),
            invalidatesTags: ['PaperSize'],
        }),
        getPaperSizes: builder.query<PaperSizeRecord[], void>({
            query: () => ({
                url: prependDreamUrl('api/roagent/printer/PaperSize'),
                method: 'GET',
            }),
            providesTags: ['PaperSize'],
        }),
        updatePaperSize: builder.mutation<void, UpdatePaperSizeRequest>({
            query: request => ({
                url: prependDreamUrl(`api/roagent/printer/PaperSize/${request.Id}`),
                body: { ...request },
                method: 'PUT',
            }),
            invalidatesTags: ['PaperSize'],
        }),
        deletePaperSize: builder.mutation<void, number>({
            query: id => ({
                url: prependDreamUrl(`api/roagent/printer/PaperSize/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: ['PaperSize'],
        }),

        createAssignment: builder.mutation<void, CreateAssignmentRequest>({
            query: body => ({
                url: prependDreamUrl('api/roagent/printer/Assignment'),
                body,
                method: 'POST',
            }),
            invalidatesTags: ['Assignment'],
        }),
        getAssignment: builder.query<AssignmentPrinterNameResponse, AssignmentPrinterNameRequest>({
            query: request => ({
                url: prependDreamUrl('api/roagent/printer/assignment/printer'),
                method: 'GET',
                params: request,
            }),
            providesTags: ['Assignment'],
        }),
        getAssignments: builder.query<AssignmentRecord[], void>({
            query: () => ({
                url: prependDreamUrl('api/roagent/printer/Assignment'),
                method: 'GET',
            }),
            providesTags: ['Assignment'],
        }),
        updateAssignment: builder.mutation<void, UpdateAssignmentRequest>({
            query: request => ({
                url: prependDreamUrl(`api/roagent/printer/Assignment/${request.Id}`),
                body: { ...request },
                method: 'PUT',
            }),
            invalidatesTags: ['Assignment'],
        }),
        deleteAssignment: builder.mutation<void, number>({
            query: id => ({
                url: prependDreamUrl(`api/roagent/printer/Assignment/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: ['Assignment'],
        }),
    }),

    overrideExisting: false,
});
